import { 
    CARREGAR_PROCESSO,
    CARREGAR_PROCESSO_SUCCESS,
    CARREGAR_PROCESSO_FAILED
} from "./actions";

const INIT_STATE = {
    processo: null,
    loading: false
};

const processos = (state = INIT_STATE, { payload, type }) => {
    switch (type) {
        case CARREGAR_PROCESSO:
            return { ...state, loading: true };
        case CARREGAR_PROCESSO_SUCCESS:
            return { ...state, processo: payload.dados, loading: false };
        case CARREGAR_PROCESSO_FAILED:
            return { ...state, processo: null, loading: false };
        
        default:
            return state;
    }
}

export default processos;