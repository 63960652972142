// @flow

import { combineReducers } from 'redux';
import auth from './auth/reducers';
import appMenu from './appMenu/reducers';
import livros from './livros/reducers';
import atos from './atos/reducers';
import protocolos from './protocolos/reducers';
import selos from './selos/reducers';
import processos from './processos/reducers';
import anexos from './anexos/reducers';
import abaAberta from './abaAberta/reducers';
import { LOGOUT_SUCCESS } from './actions';
import storage from 'redux-persist/lib/storage';

const appReducer = combineReducers({
    auth,
    appMenu,
    livros,
    atos,
    protocolos,
    selos,
    processos,
    anexos,
    abaAberta
});

const rootReducer = (state, action) => {
    if (action.type === LOGOUT_SUCCESS) {
        storage.removeItem('persist:root');
        return appReducer(undefined, action);
    }

    return appReducer(state, action);
}

export default rootReducer;