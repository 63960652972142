import React from 'react';
import { useSelector } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import { CNS_CORREGEDORIA } from '../../constants/business';
import imgStart from '../../assets/images/runner_start.svg';
import PageTitle from '../../components/PageTitle';
import { LogWidget } from '../../components/LogWidget';

const InicioCorregedoria = () => {
    return (
        <React.Fragment>
            <div className={`account-pages mt-5`}>
                <Container>
                    <Row className="justify-content-center">
                        <Col xl={4} lg={5}>
                            <div className="text-center my-5">
                                <div>
                                    <img src={imgStart} alt="Selecione um cartório para começar!" className="img-fluid hero-img-corregedoria" /> :
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col className="text-center">
                            <h3 className="mt-3">Selecione um cartório para começar!</h3>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

const InicioCartorios = () => {
    return <React.Fragment>
        <div className={`account-pages content-page-menu-left mt-6`}>
            <Container>
                <Row className="page-title">
                    <Col>
                        <PageTitle breadCrumbItems={[]} />
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col xl={4} sm={12}>
                        <h1>Teste</h1>
                    </Col>
                    <Col xl={8} sm={12}>
                        <LogWidget />
                    </Col>
                </Row>
            </Container>
        </div>
    </React.Fragment>
}

export const Inicio = () => {
    const { cartorioAtual } = useSelector((state) => state.auth);

    return (
        <React.Fragment>
            {cartorioAtual && cartorioAtual.cns == CNS_CORREGEDORIA && <InicioCorregedoria />}
            {cartorioAtual && cartorioAtual.cns != CNS_CORREGEDORIA && <InicioCartorios />}
        </React.Fragment>
    );
};
