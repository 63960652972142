export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILED = 'LOGIN_USER_FAILED';

export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_FAILED = 'REGISTER_USER_FAILED';

export const LOGOUT_USER = 'LOGOUT_USER';

export const FORGET_PASSWORD = 'FORGET_PASSWORD';
export const FORGET_PASSWORD_SUCCESS = 'FORGET_PASSWORD_SUCCESS';
export const FORGET_PASSWORD_FAILED = 'FORGET_PASSWORD_FAILED';

export const TROCAR_CARTORIO = 'TROCAR_CARTORIO';
export const TROCAR_CARTORIO_SUCCESS = 'TROCAR_CARTORIO_SUCCESS';
export const TROCAR_CARTORIO_FAILED = 'TROCAR_CARTORIO_FAILED';

export const LOGOUT = '@auth/LOGOUT';
export const LOGOUT_SUCCESS = '@auth/LOGOUT_SUCCESS';
export const LOGOUT_FAILED = '@auth/LOGOUT_FAILED';

export const LOGIN_USER_UPDATE_MENU = '@auth/LOGIN_UPDATE_MENU';

export const loginUser = (username, password) => ({
    type: LOGIN_USER,
    payload: { username, password },
});

export const loginUserSuccess = (user, cartorio_atual, permissoes, cartorios) => ({
    type: LOGIN_USER_SUCCESS,
    payload: {
        user,
        cartorio_atual,
        permissoes,
        cartorios
    },
});

export const loginUserUpdateMenu = (livros) => ({
    type: LOGIN_USER_UPDATE_MENU,
    payload: { livros }
});

export const loginUserFailed = (error) => ({
    type: LOGIN_USER_FAILED,
    payload: error,
});

export const registerUser = (fullname, email, password) => ({
    type: REGISTER_USER,
    payload: { fullname, email, password },
});

export const registerUserSuccess = (user) => ({
    type: REGISTER_USER_SUCCESS,
    payload: user,
});

export const registerUserFailed = (error) => ({
    type: REGISTER_USER_FAILED,
    payload: error,
});

export const logoutUser = ({ query }) => ({
    type: LOGOUT_USER,
    payload: { query },
});

export const forgetPassword = (username) => ({
    type: FORGET_PASSWORD,
    payload: { username },
});

export const forgetPasswordSuccess = (passwordResetStatus) => ({
    type: FORGET_PASSWORD_SUCCESS,
    payload: passwordResetStatus,
});

export const forgetPasswordFailed = (error) => ({
    type: FORGET_PASSWORD_FAILED,
    payload: error,
});

export const trocarCartorio = (cartorio) => ({
    type: TROCAR_CARTORIO,
    payload: cartorio,
});

export const trocarCartorioSuccess = (data) => ({
    type: TROCAR_CARTORIO_SUCCESS,
    payload: data,
});

export const trocarCartorioFailed = () => ({
    type: TROCAR_CARTORIO_FAILED,
});

export const logout = () => ({
    type: LOGOUT,
});

export const logoutFailed = () => ({
    type: LOGOUT_FAILED,
});

export const logoutSuccess = () => ({
    type: LOGOUT_SUCCESS,
});
