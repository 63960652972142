/**
 * @param valor O valor a ser convertido.
 * @returns O valor como float ou zero caso não seja um valor numerico.
 */
export const floatOuZero = (valor) => {
    const numero = parseFloat(valor);
    if (Number.isNaN(numero)) {
        console.error(`Não foi possível converter ${valor} para float.`);
        return 0;
    }

    return numero;
}

/**
 * @param valor O valor a ser convertido.
 * @returns O valor como float ou zero caso não seja um valor numerico.
 */
 export const formatarReais = (valor) => {
    let resultado = valor;
    if (typeof valor !== 'number') {
        resultado = floatOuZero(valor);
    }

    return 'R$ ' + resultado.toFixed(2).replace('.', ',');
}

/**
 * @param valor O valor a ser convertido.
 * @returns O valor como float ou zero caso não seja um valor numerico.
 */
export const formatarHoraMinutoSegundo = (milissegundos) => {
    let h,m,s;
    h = Math.floor(milissegundos/1000/60/60);
    m = Math.floor((milissegundos/1000/60/60 - h)*60);
    s = Math.floor(((milissegundos/1000/60/60 - h)*60 - m)*60);

    s = s < 10 ? `0${s}`: `${s}`
    m = m < 10 ? `0${m}`: `${m}`
    h = h < 10 ? `0${h}`: `${h}`

    return (h != '00' ? `${h}:` : '') + `${m}:` + s;
}