import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import reducers from './reducers';
import sagas from './sagas';

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];

const persistConfig = {
    key: 'root',
    storage,
    blacklist: [],
};

const persistedReducers = persistReducer(persistConfig, reducers);

export function configureStore(initialState) {
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    const store = createStore(persistedReducers, initialState, composeEnhancers(applyMiddleware(...middlewares)));
    const persistor = persistStore(store);
    sagaMiddleware.run(sagas);
    return { store, persistor };
}

export const { store, persistor } = configureStore();
