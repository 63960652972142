import { all, call, put, takeEvery } from "redux-saga/effects";
import apiCorreicoes from "../../helpers/apiCorreicoes";
import { toastError } from "../toast/actions";
import { listarAtosDeLivroFailed, listarAtosDeLivroSuccess, listarSublivrosFailed, listarSublivrosSuccess, LISTAR_ATOS_DE_LIVRO, LISTAR_SUBLIVROS } from "./actions";

function* listarSublivros({ payload: { livro, pagina } }) {
    let error = 'Não foi possível listar os livros.';
    try {
        const response = yield call(apiCorreicoes, 'get', `/livros/${livro}?pagina=${pagina}`);
        const { status, data } = response;

        switch (status) {
            case 200:
                yield put(listarSublivrosSuccess(data));
                break;
            default:
                error = data.erro || error;
                throw error;
        }
    } catch {
        yield put(toastError(error));
        yield put(listarSublivrosFailed());
    }
}

function* listarAtosDeLivro({ payload: { livroId, livro, pagina } }) {
    let error = 'Não foi possível listar os atos';
    try {
        const response = yield call(apiCorreicoes, 'get', `/atos/${livroId}/${livro}?page=${pagina}`);
        const { status, data } = response;

        switch (status) {
            case 200:
                yield put(listarAtosDeLivroSuccess(data));
                break;
            default:
                error = data.erro || error;
                throw error;
        }
    } catch {
        yield put(toastError(error));
        yield put(listarAtosDeLivroFailed());
    }
}

function* livrosSaga() {
    yield all([
        takeEvery(LISTAR_ATOS_DE_LIVRO, listarAtosDeLivro), 
        takeEvery(LISTAR_SUBLIVROS, listarSublivros), 
    ]);
}

export default livrosSaga;