// @flow
import {
    LOGIN_USER,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_FAILED,
    LOGOUT_USER,
    REGISTER_USER,
    REGISTER_USER_SUCCESS,
    REGISTER_USER_FAILED,
    FORGET_PASSWORD,
    FORGET_PASSWORD_SUCCESS,
    FORGET_PASSWORD_FAILED,
    TROCAR_CARTORIO_FAILED,
    TROCAR_CARTORIO_SUCCESS,
    TROCAR_CARTORIO,
    LOGOUT_SUCCESS,
} from './actions';

const INIT_STATE = {
    user: null,
    loading: false,
    cartorioAtual: null,
    cartoriosRegistro: null,
    permissoes: [],
    cartorios: [],
};

const auth = (state = INIT_STATE, action) => {
    switch (action.type) {
        case LOGIN_USER:
            return { ...state, loading: true };
        case LOGIN_USER_SUCCESS:
            return {
                ...state,
                user: action.payload.user,
                loading: false,
                error: null,
                cartorioAtual: action.payload.cartorio_atual,
                permissoes: action.payload.permissoes,
                cartorios: action.payload.cartorios,
            };
        case LOGIN_USER_FAILED:
            return { ...state, error: action.payload, loading: false };

        case REGISTER_USER:
            return { ...state, loading: true };
        case REGISTER_USER_SUCCESS:
            return { ...state, user: action.payload, loading: false, error: null };
        case REGISTER_USER_FAILED:
            return { ...state, error: action.payload, loading: false };

        case FORGET_PASSWORD:
            return { ...state, loading: true };
        case FORGET_PASSWORD_SUCCESS:
            return { ...state, passwordResetStatus: action.payload, loading: false, error: null };
        case FORGET_PASSWORD_FAILED:
            return { ...state, error: action.payload, loading: false };

        case TROCAR_CARTORIO:
            return { ...state, loading: true };
        case TROCAR_CARTORIO_SUCCESS:
            return {
                ...state,
                loading: false,
                cartorioAtual: action.payload.cartorio_atual,
                user: {
                    ...state.user,
                    token: action.payload.token,
                },
                permissoes: action.payload.permissoes,
            };
        case TROCAR_CARTORIO_FAILED:
            return { ...state, loading: false };

        case LOGOUT_USER:
        case LOGOUT_SUCCESS:
            return INIT_STATE;
        default:
            return state;
    }
};

export default auth;
