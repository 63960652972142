export const LISTAR_PROTOCOLOS = '@protocolos/LISTAR_PROTOCOLOS';
export const LISTAR_PROTOCOLOS_SUCCESS = '@protocolos/LISTAR_PROTOCOLOS_SUCCESS';
export const LISTAR_PROTOCOLOS_FAILED = '@protocolos/LISTAR_PROTOCOLOS_FAILED';
export const FORCAR_ATUALIZACAO_PROTOCOLOS = '@protocolos/FORCAR_ATUALIZACAO_PROTOCOLOS';

export const listarProtocolos = (livro, pagina) => ({
    type: LISTAR_PROTOCOLOS,
    payload: { livro, pagina }
});

export const listarProtocolosSuccess = (dados) => ({
    type: LISTAR_PROTOCOLOS_SUCCESS,
    payload: { dados }
});

export const listarProtocolosFailed = () => ({
    type: LISTAR_PROTOCOLOS_FAILED,
});

export const forcarAtualizacaoProtocolos = (livro, pagina) => ({
    type: FORCAR_ATUALIZACAO_PROTOCOLOS,
    payload: { livro, pagina }
});
