import { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import jwtDecode from 'jwt-decode';
import { useHistory } from 'react-router-dom';
import apiCorreicoes from '../helpers/apiCorreicoes';
import { loginUserSuccess, loginUserUpdateMenu } from '../store/actions';
import { getLoggedInUser, setSession } from '../helpers/auth';
import { useQuery } from './UseQuery';

export const autenticar = async () => {
    const url = await pegarUrl();
    window.open(url, '_self');
};

export const pegarUrl = async () => {
    try {
        /**
         * TODO: verificar esta rota
         */
        const { status, data } = await apiCorreicoes('get', '/autenticacao/url');
        if (status === 200) {
            return data.url;
        } else {
            throw status;
        }
    } catch (e) {
        console.warn('Erro ao autenticar', e);
    }

    return null;
}

export const useAuthenticated = (pathname) => {
    const dispatch = useDispatch();
    const query = useQuery();
    const history = useHistory();
    const { user: userState, cartorioAtual, cartorios } = useSelector((state) => state.auth);
    const [loading, setLoading] = useState(false);
    const [userRequest, setUserRequest] = useState(null);
    const [cartorioRequest, setCartorioRequest] = useState(null);
    const [requestSuccess, setRequestSuccess] = useState(false)

    const doLogin = (status, data) => {
        if (status === 200) {
            setRequestSuccess(true);
            const copiaUsuario = { ...data.usuario, token: data.token };
            data.usuario.token = data.token;
            data.usuario.cartorioAtual = data.cartorio_atual;
            setSession(data.usuario);
            dispatch(loginUserSuccess(copiaUsuario, data.cartorio_atual, data.permissoes, data.cartorios));
            // carregar livros aqui
            dispatch(loginUserUpdateMenu(data.livros));
            setLoading(false);
            setUserRequest(copiaUsuario);
            setCartorioRequest(data.cartorio_atual);
            history.push('/inicio');
        } else if (status === 403) {
            history.push('/error/403');
        } else {
            if (window.location.pathname == '/autenticar') {
                history.push('/error/500');
            }
        }
    }

    const getUsuario = useCallback(
        async (codigo, state) => {
            try {
                if (!loading && !requestSuccess) {
                    setLoading(true);

                    const { status, data } = await apiCorreicoes('post', '/autenticacao/validar', {
                        codigo,
                        state,
                    });

                    doLogin(status, data);
                }
            } catch (e) {
                console.warn('Erro ao buscar token', e);
            }
        },
        []
    );

    useEffect(async () => {
        const code = query.get('code');
        if (pathname === '/autenticar' && code) {
            getUsuario(code, query.get('state'));
        } else {
            const userCookies = getLoggedInUser();
            
            try {
                if (userCookies && userCookies.token && Array.isArray(cartorios) && cartorios.length == 0) {
                    setLoading(true);
                    const { status, data } = await apiCorreicoes('get', `autenticacao/selecionar-cartorio/${userCookies.cartorioAtual.cns}`);
                    doLogin(status, data);
                } else {
                    const decoded = jwtDecode((userState || userRequest || userCookies)?.token);
                    const currentTime = Date.now() / 1000;
                    
                    if (decoded.exp < currentTime) {
                        autenticar();
                    }
                }
            } catch (e) {
                console.warn('Erro ao validar codigo ou token', e, userState, userCookies);
            }
        }
    }, [pathname]);

    return { user: userState || userRequest, cartorioAtual: cartorioAtual || cartorioRequest, loading };
};
