// @flow
import jwtDecode from 'jwt-decode';
import cookies from './cookies';

/**
 * Checks if user is authenticated
 */
const isUserAuthenticated = () => {
    const user = getLoggedInUser();
    if (!user) {
        return false;
    }

    const decoded = jwtDecode(user.token);
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
        console.warn('Access token expired.');
        return false;
    } else {
        return true;
    }
};

const setSession = user => {
    if (user) {
        cookies().set('user', user);
    } else {
        cookies().remove('user');
    }
};

/**
 * Returns the logged in user
 */
const getLoggedInUser = () => {
    const user = cookies().get('user');
    return user ? (typeof user == 'object' ? user : JSON.parse(user)) : null;
};

const getToken = () => {
    const user = getLoggedInUser();

    if (user) {
        return user.token;
    }

    return null;
}

const goToAccounts = uri => {
    window.location.href = process.env.REACT_APP_ACCOUNTS_FRONT + uri;
}

export { isUserAuthenticated, getLoggedInUser, setSession, getToken, goToAccounts };
