import { format } from "date-fns";
import React, { useCallback, useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { AlertCircle, Edit, RefreshCw } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import { Button, Card, CardBody, Col, Row, UncontrolledTooltip } from "reactstrap";
import PreLoaderWidget, { TableLoader } from "../../components/Loader";
import { ModaisDeVisualizacao } from "../../components/ModaisDeVisualizacao";
import PageTitle from "../../components/PageTitle";
import { Paginator } from "../../components/Paginator";
import { TextoVazio } from "../../components/Vazio";
import { encontrarLivro } from "../../helpers/livros";
import { forcarAtualizacaoProtocolos, listarProtocolos } from "../../store/protocolos/actions";

export const Protocolos = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { especialidade: especialidadeId, livro: livroId, page } = useParams();
    const { especialidade, livro } = useSelector(({ appMenu: { menuItems } }) => ({
        especialidade: encontrarLivro(especialidadeId, menuItems),
        livro: encontrarLivro(livroId, menuItems)
    }));

    const aplicarColspan = (cell, row, rowIndex, colIndex) => {
        if (row.vazio) {
            return { colSpan: `3` }
        }
    }
    const aplicarOcultar = (cell, row, rowIndex, colIndex) => {
        if (row.vazio) {
            return { hidden: true }
        }
    }

    const columns = React.useMemo(
        () => [
            {
                text: 'Protocolo',
                dataField: 'numero',
            },
            {
                text: 'Data',
                dataField: 'data',
                formatter: (_, row) => row.data ? format(new Date(row.data), "dd/MM/yyyy HH:mm:ss") : row.natureza_titulo,
                attrs: aplicarColspan,
            },
            // {
            //     text: 'Apresentante',
            //     dataField: 'apresentante',
            // },
            // {
            //     text: 'Natureza do Título',
            //     dataField: 'natureza_titulo',
            // },
            {
                text: 'Anotações',
                dataField: 'anotacoes',
                formatter: (_, row) => <TextoVazio>{row.anotacoes}</TextoVazio>,
                attrs: aplicarOcultar,
            },
            {
                text: 'Selo',
                dataField: 'selo',
                formatter: (_, row) => (
                    !row.selo ? <TextoVazio/> :
                        <Link
                            to="#" onClick={() => abrirModal(row.selo, 'selo')}>
                                <u>{row.selo}</u>
                        </Link>
                ),
                attrs: aplicarOcultar,
            },
        ],
        []
    );

    const {
        loading,
        data: {
            pagina_atual: paginaAtual,
            tem_pagina_anterior: temPaginaAnterior,
            tem_proxima_pagina: temProximaPagina,
            dados: protocolos,
        },
    } = useSelector(({ protocolos }) => protocolos);

    const [carregamentoLentoTimer, setCarregamentoLentoTimer] = useState(null);
    const [carregamentoLento, setCarregamentoLento] = useState(false);
    useEffect(() => {
        if (loading && !carregamentoLento) {
            const timer = setTimeout(() => {
                setCarregamentoLento(true);
            }, 3000);
            setCarregamentoLentoTimer(timer);
        } else {
            clearTimeout(carregamentoLentoTimer);
            setCarregamentoLentoTimer(null);
            setCarregamentoLento(false);
        }
    }, [loading]);

    useEffect(() => {
        dispatch(listarProtocolos(livro.prefixo_livro, page));
    }, [livro.livro, page, dispatch]);

    const forcarAtualizacao =  () => {
        dispatch(forcarAtualizacaoProtocolos(livro.prefixo_livro, page));
    };

    const goToPaginaAnterior = useCallback(() => {
        history.push(`/especialidades/${especialidadeId}/protocolos/${livroId}/${(paginaAtual || page) - 1}`);
    }, [history, especialidadeId, livroId, paginaAtual, page]);
    
    const goToProximaPagina = useCallback(() => {
        history.push(`/especialidades/${especialidadeId}/protocolos/${livroId}/${(paginaAtual || page) + 1}`);
    }, [history, especialidadeId, livroId, paginaAtual, page]);

    const [modalAberta, setModalAberta] = useState(false);
    const [objetoId, setObjetoId] = useState('');
    const [tipoObjeto, setTipoObjeto] = useState('');
    const fecharModal = () => {
        setModalAberta(false);
        setObjetoId('');
        setTipoObjeto('');
    }
    const abrirModal = (idObjeto, tipoObjeto) => {
        setModalAberta(true);
        setObjetoId(idObjeto);
        setTipoObjeto(tipoObjeto);
    }

    return (
        <div className="content-page-menu-left">
            <Col className="container">
                <Row className="page-title">
                    <Col>
                        <PageTitle breadCrumbItems={[
                            { label: especialidade.titulo, path: especialidade.path },
                            { label: livro.titulo, active: true }
                        ]} />
                    </Col>
                </Row>
                <Card>
                    <CardBody>
                        {carregamentoLento && <PreLoaderWidget mensagem="O carregamento dos protocolos pode demorar alguns segundos para completar." />}
                        <Row>
                            <Col>
                                <Row className="mb-2">
                                    <Col>
                                        <h4 className="mt-2">{livro.titulo}</h4>
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                {/* <Button color={"primary"} className="float-right d-flex align-items-center">
                                    <Edit size={18} className="mr-2" /> Fazer Anotação
                                </Button> */}
                                <Button id="forcar-atualizacao" color="white" className="float-right d-flex align-items-center mr-2" onClick={forcarAtualizacao}>
                                    <UncontrolledTooltip target={"forcar-atualizacao"}>
                                        Para melhor experiência dos usuários, os protocolos são armazenados em cache e atualizados apenas diariamente. Use esse botão para forçar a atualização imediata.
                                    </UncontrolledTooltip>
                                    <RefreshCw size={20} />
                                </Button>
                            </Col>
                        </Row>
                        {loading && <TableLoader colunas="4" linhas={carregamentoLento ? 5 : 20} />}
                        {!loading && protocolos?.length ? <BootstrapTable
                            bootstrap4
                            striped
                            keyField="numero"
                            remote
                            onTableChange={() => null}
                            data={protocolos}
                            columns={columns}
                            wrapperClasses="table-responsive"
                        /> : null}
                        {protocolos?.length == 0 && !loading ? <Col className="d-flex flex-column justify-content-center align-items-center">
                            <AlertCircle />
                            <h6 className="font-size-15">Nenhum protocolo cadastrado</h6>
                        </Col> : null}
                        {protocolos?.length > 0 && <Row className="mt-4">
                            <Col>
                                <Row className="float-right">
                                    <Col>
                                        <Paginator
                                            paginaAtual={paginaAtual} 
                                            proximaPagina={goToProximaPagina}
                                            paginaAnterior={goToPaginaAnterior}
                                            temProximaPagina={temProximaPagina}
                                            temPaginaAnterior={temPaginaAnterior}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>}
                    </CardBody>
                </Card>
            </Col>
            <ModaisDeVisualizacao id={objetoId} tipo={tipoObjeto} aberto={modalAberta} onClose={fecharModal} onNavigate={abrirModal} />
        </div>
    )
}