import React from 'react';
import { ModalProcesso } from "./ModalProcesso";
import { ModalSelo } from "./ModalSelo";

export const ModaisDeVisualizacao = ({tipo, id, aberto, onClose, onNavigate}) => {
    switch (tipo) {
        case 'processo':
            return <ModalProcesso id={id} aberto={aberto} onClose={onClose} onNavigate={onNavigate} />
        case 'selo':
            return <ModalSelo codigoSelo={id} aberto={aberto} onClose={onClose} onNavigate={onNavigate} />
    
        default:
            return <></>
    }
}