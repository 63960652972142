import { all, takeLatest, fork, call } from 'redux-saga/effects';
import { TOAST_SUCCESS, TOAST_WARNING, TOAST_ERROR, TOAST_INFO } from './constants';
import { toast } from 'react-toastify';

const TOAST_CONFIG = {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
};

function* toastSuccess({ payload }) {
    yield call(toast.success, payload, { ...TOAST_CONFIG, toastId: payload });
}

function* toastWarning({ payload }) {
    yield call(toast.warning, payload, { ...TOAST_CONFIG, toastId: payload });
}

function* toastError({ payload }) {
    yield call(toast.error, payload, { ...TOAST_CONFIG, toastId: payload });
}

function* toastInfo({ payload }) {
    yield call(toast.info, payload, { ...TOAST_CONFIG, toastId: payload });
}

function* watchToastSuccess() {
    yield takeLatest(TOAST_SUCCESS, toastSuccess);
}

function* watchToastWarning() {
    yield takeLatest(TOAST_WARNING, toastWarning);
}

function* watchToastError() {
    yield takeLatest(TOAST_ERROR, toastError);
}

function* watchToastInfo() {
    yield takeLatest(TOAST_INFO, toastInfo);
}

function* toastSaga() {
    yield all([
        fork(watchToastSuccess),
        fork(watchToastWarning),
        fork(watchToastError),
        fork(watchToastInfo)
    ])
}

export default toastSaga;