import { all, call, put, takeEvery } from "redux-saga/effects";
import apiCorreicoes from "../../helpers/apiCorreicoes";
import { toastError } from "../toast/actions";
import { carregarProcessoFailed, carregarProcessoSuccess, CARREGAR_PROCESSO } from "./actions";

function* carregarProcesso({ payload: { id } }) {
    let error = 'Não foi possível encontrar o processo.';

    try {
        const response = yield call(apiCorreicoes, 'get', `/processos/${id}`);
        const { status, data } = response;

        switch (status) {
            case 200:
                yield put(carregarProcessoSuccess(data));
                break;
            default:
                error = data.erro || error;
                throw error;
        }
    } catch {
        yield put(toastError(error));
        yield put(carregarProcessoFailed());
    }
}

function* processosSaga() {
    yield all([
        takeEvery(CARREGAR_PROCESSO, carregarProcesso), 
    ]);
}

export default processosSaga;