import { FORCAR_ATUALIZACAO_PROTOCOLOS, LISTAR_PROTOCOLOS, LISTAR_PROTOCOLOS_FAILED, LISTAR_PROTOCOLOS_SUCCESS } from "./actions";

const INIT_STATE = {
    data: {},
};

const protocolos = (state = INIT_STATE, { payload, type }) => {
    switch (type) {
        case FORCAR_ATUALIZACAO_PROTOCOLOS:
        case LISTAR_PROTOCOLOS:
            return { ...state, loading: true };
        case LISTAR_PROTOCOLOS_SUCCESS:
            return { ...state, data: payload.dados, loading: false };
        case LISTAR_PROTOCOLOS_FAILED:
            return { ...state, loading: false };
        default:
            return state;
    }
}

export default protocolos;