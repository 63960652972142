import React from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import PageTitle from '../../components/PageTitle';

export const CorreicaoAtual = () => {
    return (
        <div className="content-page-menu-left">
            <Col className="container">
                <Row className="page-title">
                    <Col>
                        <PageTitle breadCrumbItems={[{ label: 'Dados da Correição Atual', active: true }]} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Card>
                            <CardBody>
                                <Row className="mb-4">
                                    <Col>
                                        <div className="d-flex justify-content-space-between align-items-center">
                                            <h4 className="mt-2">Dados da Correição Atual</h4>
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Col>
        </div>
    );
};
