import { format, parseISO } from "date-fns";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {  Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { DescriptionTable } from "./DescriptionTable";
import { CardTitleLoader, DescriptionTableLoader } from "./Loader";
import { TextoVazio } from "./Vazio";
import { floatOuZero, formatarReais } from "../helpers/numero";
import { carregarSelo } from "../store/actions";
import { Link } from "./Link";

export const ModalSelo = ({ codigoSelo, aberto, onClose, onNavigate }) => {
    const dispatch = useDispatch();
    const [dadosModal, setDadosModal] = useState(null);
    const { selo, loading } = useSelector(({ appMenu: { menuItems }, selos: { selo, loading } }) => ({
        selo,
        loading,
    }));

    const formatar = (valor) => {
        switch (valor) {
            case 'true':
                return 'Sim';
            case 'false':
                return 'Não';
            default:
                return valor;
        }
    }

    const formatarEmolumentos = (atos) => {
        if (!atos) return <TextoVazio/>;
        
        let emolumentos = 0;
        let tfj = 0;
        let funcivil = 0;
        let fse = 0;
        let iss = 0;
        let total = 0;

        for (let i = 0; i < atos.length; i++) {
            const ato = atos[i];
            emolumentos += floatOuZero(ato.emolumentos);
            tfj += floatOuZero(ato.tfj);
            funcivil += floatOuZero(ato.funcivil);
            fse += floatOuZero(ato.fse);
            iss += floatOuZero(ato.iss);
            total += floatOuZero(ato.total);
        }

        emolumentos = formatarReais(emolumentos);
        tfj = formatarReais(tfj);
        funcivil = formatarReais(funcivil);
        fse = formatarReais(fse);
        iss = formatarReais(iss);
        total = formatarReais(total);

        return `Emolumentos (Cartório): ${emolumentos}; TFJ (Tribunal de Justiça): ${tfj}; Funcivil (Tribunal de Justiça): ${funcivil}; FSE (Tribunal de Justiça): ${fse}; e ISS (Município): ${iss}; TOTAL: ${total}.`;
    }

    useEffect(() => {
        dispatch(carregarSelo(codigoSelo));
    }, [codigoSelo, dispatch]);

    useEffect(() => {
        const econtrarDadoDoAto = (ato, chave) => {
            if (!ato || !ato.dados) {
                return null;
            }
    
            for (let i = 0; i < ato.dados.length; i++) {
                const dado = ato.dados[i];
                if (dado['nome'] === chave) {
                    return formatar(dado['valor']);
                }
            }
    
            return null;
        }
        
        const d = [
            {rotulo: 'Ato', valor: selo?.atos[0].codigo + ' - ' + (selo?.atos[0]?.emolumento?.apelido || selo?.atos[0]?.emolumento?.nome_ato || "") },
            {rotulo: 'Data do Ato', valor: (selo && selo.atos && selo.atos.length && selo.atos[0].data && format(parseISO(selo.atos[0].data), 'dd/MM/yyyy')) || <TextoVazio/>},
            {rotulo: 'Selo Digital TJTO', valor: <a href={`https://gise.tjto.jus.br/Gise/qr?c=${codigoSelo.replace('-', '&v=')}`} target="_blank">Ver selo digital</a>},
            {rotulo: 'Emolumentos', valor: formatarEmolumentos(selo?.atos)},
            // {rotulo: 'Conteúdo do Ato', valor: <TextoVazio/>},
            // {rotulo: 'Indicador Pessoal', valor: <TextoVazio/>},
            // {rotulo: 'Indicador Real', valor: <TextoVazio/>},
            // {rotulo: 'Transcrições, Anotações e Averbações', valor: ''},
            {rotulo: 'Diferido', valor: econtrarDadoDoAto(selo?.atos[0], 'diferido')},
            {rotulo: 'Gratuito', valor: econtrarDadoDoAto(selo?.atos[0], 'gratuito')},
        ];

        if (selo?.processo) {
            d.splice(2, 0, {rotulo: 'N° do Processo', valor: selo?.processo ? <Link  onClick={() => {
                onNavigate(selo?.processo.id, 'processo');
            }}>{selo?.processo.numero}</Link> : <TextoVazio/> });
        }

        const livro = econtrarDadoDoAto(selo?.atos[0], 'livro');
        if (livro) {
            d.push({rotulo: 'Livro', valor: livro});
        }

        const folha = econtrarDadoDoAto(selo?.atos[0], 'folha');
        if (folha) {
            d.push({rotulo: 'Folha', valor: folha});
        }

        const natureza = econtrarDadoDoAto(selo?.atos[0], 'natureza_titulo');
        if (natureza) {
            d.push({rotulo: 'Natureza do Titulo', valor: natureza});
        }

        const protocolo = econtrarDadoDoAto(selo?.atos[0], 'numero_protocolo');
        if (protocolo) {
            d.unshift({rotulo: 'Número do Protocolo', valor: protocolo});
        }

        setDadosModal(d);
    }, [ selo ])

    return (
        <Modal
            isOpen={aberto}
            toggle={onClose}
            className="modal-dialog-centered"
            style={{ zIndex: 9999999999 }}
            size="lg">
            <ModalHeader>
                {loading && <CardTitleLoader />}
                {!loading && `Selo ${codigoSelo}`}
            </ModalHeader>
            <ModalBody>
                {loading && <DescriptionTableLoader />}
                {!loading && 
                    <Row>
                        <Col>
                            <DescriptionTable headerWidth={200} data={dadosModal} chave="rotulo" valor="valor" />
                        </Col>
                    </Row>
                }
            </ModalBody>
            <ModalFooter>
                <Button
                    color="primary"
                    className="ml-1"
                    onClick={onClose}>
                    Ok
                </Button>
            </ModalFooter>
        </Modal>
    )
}
