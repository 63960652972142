import axios from 'axios';
import { getToken } from './auth';
import { store } from '../store/store';

const http = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});

http.interceptors.request.use(async (config) => {
    const token = getToken();
    config.headers.Authorization = `Bearer ${token}`;
    config.headers['Front-Url'] = window.location.href;
    return config;
});

/**
 * TODO: Renomear este método
 * @param {*} method 
 * @param {*} url 
 * @param {*} data 
 * @param {*} params 
 * @param {*} headers 
 * @param {*} onUploadProgress 
 * @param {*} onDownloadProgress 
 * @param {*} cancelToken 
 * @returns 
 */
const apiCorreicoes = async (method, url, data = {}, params = {}, headers = {}, onUploadProgress, onDownloadProgress, cancelToken) =>
    http
        .request({
            method,
            url,
            data,
            params,
            headers,
            onUploadProgress,
            onDownloadProgress,
            cancelToken
        })
        .then((response) => response)
        .catch((error) => {
            if (error.response.status === 401) {
                store.dispatch({
                    type: 'ERROR_401',
                });
              } else {
                  return error.response;
              }
        });

export default apiCorreicoes;