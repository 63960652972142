import { toTitleCase } from "./texto";

export const encontrarLivro = (id, livros) => {
    if (!id || !livros) {
        return false;
    }

    for (let i = 0; i < livros.length; i++) {
        if (livros[i].id == id || livros[i].prefixo_livro == id) {
            return livros[i];
        }
        
        if (livros[i].livros) {
            let resultado = encontrarLivro(id, livros[i].livros);
            if (resultado) {
                return resultado;
            }
        }
    }

    return false;
}

export const formatarTituloLivroPorNome = (nome) => {
    const matches = nome.match(/_LV([\d]+)-?([A-Za-z])?$/);
    return `Livro ${matches[1]}` + (matches[2] ? ` ${matches[2]}` : '');
}

export const formatarTituloColecaoPorNome = (nome) => {
    const partes = nome.split('_');
    if (partes.length < 2) {
        return 'Livro';
    }

    return toTitleCase(partes[1].replaceAll('-', ' '));
}