export const LISTAR_SUBLIVROS = '@livros/LISTAR_SUBLIVROS';
export const LISTAR_SUBLIVROS_SUCCESS = '@livros/LISTAR_SUBLIVROS_SUCCESS';
export const LISTAR_SUBLIVROS_FAILED = '@livros/LISTAR_SUBLIVROS_FAILED';
export const LISTAR_ATOS_DE_LIVRO = '@livros/LISTAR_ATOS';
export const LISTAR_ATOS_DE_LIVRO_SUCCESS = '@livros/LISTAR_ATOS_SUCCESS';
export const LISTAR_ATOS_DE_LIVRO_FAILED = '@livros/LISTAR_ATOS_FAILED';

export const listarSublivros = (livro, pagina) => ({
    type: LISTAR_SUBLIVROS,
    payload: { livro, pagina }
});

export const listarSublivrosSuccess = (dados) => ({
    type: LISTAR_SUBLIVROS_SUCCESS,
    payload: { dados }
});

export const listarSublivrosFailed = () => ({
    type: LISTAR_SUBLIVROS_FAILED,
});

export const listarAtosDeLivro = (livroId, livro, pagina) => ({
    type: LISTAR_ATOS_DE_LIVRO,
    payload: { livroId, livro, pagina }
});

export const listarAtosDeLivroSuccess = (dados) => ({
    type: LISTAR_ATOS_DE_LIVRO_SUCCESS,
    payload: { dados }
});

export const listarAtosDeLivroFailed = () => ({
    type: LISTAR_ATOS_DE_LIVRO_FAILED,
});
