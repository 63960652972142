import React from "react";
import { TextoVazio } from "./Vazio";

export const DescriptionTable = ({ chave, valor, data, children, headerWidth }) => {
    let chaveRenderizador, valorRenderizador;

    if (typeof chave == 'string') {
        chaveRenderizador = (item) => {
            return item[chave];
        }
    } else {
        chaveRenderizador = chave
    }

    if (typeof valor == 'string') {
        valorRenderizador = (item) => {
            return item[valor] || <TextoVazio />;
        }
    } else {
        valorRenderizador = valor
    }

    return (
        <table className="table table-bordered description-table" border="0" cellSpacing="0" cellPadding="0">
            <style>
            {`
                table.table-bordered.description-table tr >td:first-child {
                    width: ${headerWidth}px;
                }
            `}
            </style>
            <tbody>
                {children ? children : data?.length && data.map((item, i) => (
                    <tr key={i}>
                        <td>{chaveRenderizador(item)}</td>
                        <td>{valorRenderizador(item)}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    )
}