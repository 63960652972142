import React, { useEffect } from "react"
import { Button, Card, CardBody, Media } from "reactstrap"
import { CardBodyHeader } from "./CardBodyHeader"
import avatar from "../assets/images/users/avatar-1.jpg"
import { Link } from "react-router-dom"
import moment from 'moment';
import { useAdapter } from "../hooks/UseAdapter"
import apiCorreicoes from "../helpers/apiCorreicoes"
import { useSelector } from "react-redux"

const LogItem = ({ imagem, nome, descricao, horario, className, children }) => {
    return <React.Fragment>
        <Media className="mt-2 p-3 bg-highlight rounded">
            <img src={imagem} className={`avatar rounded-circle mr-3 ${className}`} alt={nome} />
            <Media body>
                <span className="font-weight-bold">{nome}</span>&nbsp;
                <span className="text-muted">{descricao}</span>&nbsp;
                {children}
                <br />
                <span>{`${moment(horario).format('DD/MM')} às ${moment(horario).format('H:m')}`}</span>
            </Media>
        </Media>
    </React.Fragment>
}

export const LogWidget = () => {
    const [{ call: carregarLogs }, logs] = useAdapter(apiCorreicoes)
    const usuario = useSelector(state => state.auth.user)

    const mapaAcoes = {
        'VER': "vizualizou",
        'CRIAR': "criou",
        'EDITAR': "editou",
        'EXCLUIR': "excluiu",
    }

    const pegarNomeObjeto = (tipo) => {
        return tipo.split('\\').pop()
    }
    
    useEffect(() => {
        carregarLogs(['GET', '/logs?limite=5'])
    }, [])

    useEffect(() => {
        console.log(logs)
    }, [logs])

    return <React.Fragment>
        <Card>
            <CardBody>
                <CardBodyHeader title="Ações Realizadas Recentemente">
                    <Button color={"soft-primary"} className="float-right d-flex align-items-center">
                        Ver todas as ações
                    </Button>
                </CardBodyHeader>
                {logs && logs.dados && logs.dados.map(log => 
                    <LogItem 
                        key={log._id} 
                        imagem={log.usuario.foto ? `${process.env.REACT_APP_API_PRINCIPAL_URL}/perfil/foto/${log.usuario.foto}?token=${usuario.token}` : avatar}
                        nome={log.usuario.nome} 
                        horario={new Date(log.created_at)}
                        descricao={mapaAcoes[log.acao] + ' ' + log.prefixo_objeto}>
                        <Link to={log.link_objeto}>{pegarNomeObjeto(log.tipo_objeto)}</Link>
                    </LogItem>
                )}
            </CardBody>
        </Card>
    </React.Fragment>
}