import React, { useCallback, useEffect, useState } from 'react';
import { AlertCircle, Book } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { CardTitleLoader, TableLoader } from '../../components/Loader';
import PageTitle from '../../components/PageTitle';
import { Paginator } from '../../components/Paginator';
import { encontrarLivro } from '../../helpers/livros';
import { listarSublivros } from '../../store/livros/actions';

export const Livros = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    let { 
        especialidade: especialidadeId, 
        colecao: colecaoId, 
        page 
    } = useParams();

    if (!page) {
        page = 1;
    }
    
    const { especialidade, colecao } = useSelector(({ appMenu: { menuItems } }) => ({
        especialidade: encontrarLivro(especialidadeId, menuItems),
        colecao: encontrarLivro(colecaoId, menuItems)
    }));

    const [ breadcrumbs, setBreadcrumbs ] = useState([]);

    useEffect(() => {
        if (colecaoId) {
            dispatch(listarSublivros(colecaoId, page));
            setBreadcrumbs([
                { label: especialidade.titulo, path: `/especialidades/${especialidadeId}` },
                { label: colecao.titulo, active: true },
            ]);
        } else {
            setItens(especialidade.livros);
            setBreadcrumbs([{ label: especialidade.titulo, active: true }]);
        }
    }, [page, especialidadeId, colecaoId]);

    const [ itens, setItens ] = useState([]);
    const { livros, loading } = useSelector(({ livros: { livros, loading } }) => {
        return { livros, loading };
    });
    useEffect(() => {
        if (colecaoId && livros && livros.dados.length > 0) {
            setItens(livros.dados);
        }
    }, [livros]);

    const goToPaginaAnterior = useCallback(() => {
        if (colecaoId) {
            history.push(`/especialidades/${especialidadeId}/colecao/${colecaoId}/${Number(page) - 1}`);
        } else {
            history.push(`/especialidades/${especialidadeId}/${Number(page) - 1}`);
        }
    }, [history, especialidadeId, page]);
    
    const goToProximaPagina = useCallback(() => {
        if (colecaoId) {
            history.push(`/especialidades/${especialidadeId}/colecao/${colecaoId}/${Number(page) + 1}`);
        } else {
            history.push(`/especialidades/${especialidadeId}/${Number(page) + 1}`);
        }
    }, [history, especialidadeId, page]);

    const gerarLinkLivro = (id, livro) => {
        if (!livro.prefixo_livro) {
            return `/especialidades/${id}/colecao/${livro.id}/1`;
        }

        if (livro.prefixo_livro.endsWith('_PROTOCOLO')) {
            return `/especialidades/${especialidadeId}/protocolos/${livro.prefixo_livro}/1`;
        }

        if (colecaoId) {
            return `/especialidades/${id}/colecao/${colecaoId}/livros/${livro.id}/nome/${livro.prefixo_livro}/1`;
        }

        return `/especialidades/${id}/livros/${livro.id}/nome/${livro.prefixo_livro}/1`;
    }

    return (
        <div className="content-page-menu-left">
            <Col className="container">
                <Row className="page-title">
                    <Col>
                        <PageTitle breadCrumbItems={breadcrumbs} />
                    </Col>
                </Row>
                <Card>
                    <CardBody>
                        {loading && (
                            <div>
                                <CardTitleLoader />
                                <TableLoader colunas="1" />
                            </div>
                        )}
                        {!loading && itens.length == 0 &&
                            <Row>
                                <Col className="d-flex flex-column justify-content-center align-items-center">
                                    <AlertCircle />
                                    <h6 className="font-size-15">Nenhum livro cadastrado</h6>
                                </Col>
                            </Row>
                        }
                        {!loading && itens.length > 0 && 
                            <div>
                            <Row>
                                <Col>
                                    <Row className="mb-2">
                                        <Col>
                                            <h4 className="mt-2">{colecaoId ? colecao.titulo : especialidade.titulo}</h4>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row
                                className={`ml-1 mr-1 atos atos-par atos-card font-weight-light border-top-radius`}>
                                <Row className="w-100">
                                    <div className="w-100 ml-4 d-flex justify-content-between align-items-center">
                                        <h5
                                            className={`atos-par ml-2`}>
                                            Nome
                                        </h5>
                                        <i className={`uil uil-sort`}></i>
                                    </div>
                                </Row>
                            </Row>
                            {itens.map((livro, index) => (
                                <Link to={gerarLinkLivro(especialidadeId, livro)} key={livro.id}>
                                    <Row
                                        className={`ml-1 mr-1 atos atos-${
                                            (index + 1) % 2 === 0 ? 'par' : 'impar'
                                        } atos-card font-weight-light ${
                                            index === itens.length - 1 ? 'border-bottom-radius' : ''
                                        }`}>
                                        <Row>
                                            <div className="ml-4 d-flex align-items-center">
                                                <Book size={16} className="mx-2" />
                                                <h5
                                                    className={`atos-${
                                                        (index + 1) % 2 === 0 ? 'par' : 'impar'
                                                    }`}>
                                                    {livro.titulo}
                                                </h5>
                                            </div>
                                        </Row>
                                    </Row>
                                </Link>
                            ))}
                            {livros && livros.dados && livros.dados.length > 0 &&
                            <Row className="float-right mt-4">
                                <Col>
                                    <Paginator
                                        paginaAtual={livros.pagina_atual} 
                                        proximaPagina={goToProximaPagina}
                                        paginaAnterior={goToPaginaAnterior}
                                        temProximaPagina={livros.tem_proxima_pagina}
                                        temPaginaAnterior={livros.tem_pagina_anterior}
                                    />
                                </Col>
                            </Row>
                            }
                            </div>
                        }
                    </CardBody>
                </Card>
            </Col>
        </div>
    );
};
