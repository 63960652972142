import { all, call, put, takeEvery } from "redux-saga/effects";
import apiCorreicoes from "../../helpers/apiCorreicoes";
import { toastError } from "../toast/actions";
import { pegarAtoFailed, pegarAtoSuccess, PEGAR_ATO } from "./actions";

function* pegarAto({ payload: { id, relacionados } }) {
    let error = 'Não foi possível encontrar o ato.';
    try {
        const response = yield call(apiCorreicoes, 'get', `/ato/${id}`, {}, { ids_relacionados: relacionados });
        const { status, data } = response;

        
        switch (status) {
            case 200:
                yield put(pegarAtoSuccess(data));
                break;
            default:
                error = data.erro || error;
                throw error;
        }
    } catch {
        yield put(toastError(error));
        yield put(pegarAtoFailed());
    }
}

function* atosSaga() {
    yield all([
        takeEvery(PEGAR_ATO, pegarAto), 
    ]);
}

export default atosSaga;