import { TOAST_ERROR, TOAST_SUCCESS, TOAST_WARNING, TOAST_INFO } from './constants';

export const toastError = (message) => ({
    type: TOAST_ERROR,
    payload: message
});

export const toastSuccess = (message) => ({
    type: TOAST_SUCCESS,
    payload: message
});

export const toastWarning = (message) => ({
    type: TOAST_WARNING,
    payload: message
});

export const toastInfo = (message) => ({
    type: TOAST_INFO,
    payload: message
});
