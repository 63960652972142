import { all, call, put, takeEvery } from "redux-saga/effects";
import apiCorreicoes from "../../helpers/apiCorreicoes";
import { toastError } from "../toast/actions";
import { carregarSeloFailed, carregarSeloSuccess, CARREGAR_SELO } from "./actions";

function* carregarSelo({ payload: { codigoSelo } }) {
    let error = 'Não foi possível encontrar o selo.';
    try {
        const response = yield call(apiCorreicoes, 'get', `/selos/${codigoSelo}`);
        const { status, data } = response;

        switch (status) {
            case 200:
                yield put(carregarSeloSuccess(data));
                break;
            default:
                error = data.erro || error;
                throw error;
        }
    } catch {
        yield put(toastError(error));
        yield put(carregarSeloFailed());
    }
}

function* selosSaga() {
    yield all([
        takeEvery(CARREGAR_SELO, carregarSelo), 
    ]);
}

export default selosSaga;