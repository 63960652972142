import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";
import { DescriptionTable } from "../../components/DescriptionTable";
import { pegarAto } from '../../store/atos/actions';
import { AlertCircle } from 'react-feather';
import { CardTitleLoader, DescriptionTableLoader } from '../../components/Loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilePdf } from '@fortawesome/free-regular-svg-icons'
import { format, parseISO } from 'date-fns'
import { useQuery } from '../../hooks/UseQuery'
import PageTitle from "../../components/PageTitle";
import { TextoVazio } from "../../components/Vazio";
import { pegarUrl } from "../../store/actions";
import { ModaisDeVisualizacao } from "../../components/ModaisDeVisualizacao";
import { encontrarLivro, formatarTituloColecaoPorNome, formatarTituloLivroPorNome } from "../../helpers/livros";
import { DOCS_URL } from "../../constants/docsUrl";
import { Link } from "../../components/Link";
import { useAdapter } from "../../hooks/UseAdapter";
import apiCorreicoes from "../../helpers/apiCorreicoes";

export const CapaAto = () => {
    const { id } = useParams();
    const relacionados = useQuery().get('relacionados');
    const [ dados, setDados ] = useState(null);
    const dispatch = useDispatch();
    const { ato, loading } = useSelector(state => {
        return {
            ato: state.atos.ato,
            loading: state.atos.loading,
            // anexoUrl: state.anexos.url,
        }
    });

    const [ breadcrumbs, setBreadcrumbs ] = useState([]);
    const { especialidade: especialidadeId, livro: livroId, colecao: colecaoId, nome } = useParams();
    const { especialidade, colecao } = useSelector(({ appMenu: { menuItems } }) => ({
        especialidade: encontrarLivro(especialidadeId, menuItems),
        livro: encontrarLivro(livroId, menuItems),
        colecao: encontrarLivro(colecaoId, menuItems)
    }));

    const pegarUltimoAto = (relacionados) => {
        if (relacionados && relacionados.length)
            return extrairNomeAto(relacionados.sort((a, b) => a.id > b.id ? -1 : 1)[0].nome);
        return null;
    }

    const extrairNomeAto = (nomeAcervo) => {
        if (nomeAcervo.startsWith('NOTAS_')) {
            let numeros = nomeAcervo.match(/FL([\d]+)-([\d]+)/);
            if (numeros) {
                return `FL ${numeros[1]} - ${numeros[2]}`;
            }
        } else if (nomeAcervo.startsWith('IMOVEIS_LV2')) {
            let matricula = nomeAcervo.match(/M-([\d]+)/);
            if (matricula) {
                return `M - ${matricula[1]}`;
            }
        } else {
            let matricula = nomeAcervo.match(/R-([\d]+)/);
            if (matricula) {
                return `R - ${matricula[1]}`;
            }
        }

        return null;
    }

    useEffect(() => {
        dispatch(pegarAto(id, relacionados));
        return () => {
            setDados(null);
        }
    }, [id]);

    useEffect(() => {
        if (!ato) {
            setDados(null);
            return;
        }
        
        setDados(ato);

        if (colecaoId) {
            setBreadcrumbs([
                { label: especialidade.titulo, path: especialidade.path },
                { label: formatarTituloColecaoPorNome(nome), path: colecao.path },
                { label: formatarTituloLivroPorNome(nome), path: `/especialidades/${especialidadeId}/colecao/${colecaoId}/livros/${livroId}/nome/${nome}/1` },
                { label: extrairNomeAto(ato.ato.nome), active: true }
            ]);
        } else {
            setBreadcrumbs([
                { label: especialidade.titulo, path: especialidade.path },
                { label: nome, path: `/especialidades/${especialidadeId}/livros/${livroId}/nome/${nome}/1` },
                { label: extrairNomeAto(ato.ato.nome), active: true }
            ]);
        }
    }, [ato]);
    
    const [{ call }, anexoUrl] = useAdapter(apiCorreicoes);
    const pegarUrlAnexo =  () => {
        call(['get', `/anexo/${ato.ato.id}/acervo`]);
    };
    useEffect(() => {
        if (!anexoUrl) {
            return;
        }

        let url = anexoUrl.url;
        if (["doc", "docx"].includes(ato.ato.extensao)) {
            url = `${DOCS_URL}${encodeURIComponent(url)}`;
        }
        window.open(url, "_blank", "noopener,noreferrer");
    }, [anexoUrl]);

    const [modalAberta, setModalAberta] = useState(false);
    const [objetoId, setObjetoId] = useState('');
    const [tipoObjeto, setTipoObjeto] = useState('');
    const fecharModal = () => {
        setModalAberta(false);
        setObjetoId('');
        setTipoObjeto('');
    }
    const abrirModal = (idObjeto, tipoObjeto) => {
        setModalAberta(true);
        setObjetoId(idObjeto);
        setTipoObjeto(tipoObjeto);
    }

    useEffect(() => {
        // reset state when loading component
        setDados(null);
        setBreadcrumbs([]);
        setModalAberta(false);
        setObjetoId('');
        setTipoObjeto('');
    }, [id]);

    return (
        <div className="content-page-menu-left">
            <Col className="container">
                <Row className="page-title">
                    <Col>
                        {loading ? <CardTitleLoader width={400} /> : <PageTitle breadCrumbItems={breadcrumbs} /> }
                    </Col>
                </Row>
                <Card>
                    <CardBody>
                        {loading && <div>
                            <CardTitleLoader />
                            <DescriptionTableLoader linhas={5} headerWidth={200} />    
                        </div>}
                        {!loading && !dados && (
                            <Row>
                                <Col className="d-flex flex-column justify-content-center align-items-center">
                                    <AlertCircle />
                                    <h6 className="font-size-15">Ato não encontrado.</h6>
                                </Col>
                            </Row>
                        )}
                        {!loading && dados && (
                        <div>
                            <Row>
                                <Col>
                                    <Row className="mb-2">
                                        <Col>
                                            <h4 className="mt-2"><TextoVazio>{extrairNomeAto(dados.ato.nome)}</TextoVazio></h4>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <DescriptionTable headerWidth="200">
                                        <tr>
                                            <td>Conteúdo do Ato</td>
                                            <td>
                                                <Link onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    pegarUrlAnexo();
                                                }}>
                                                    <FontAwesomeIcon icon={faFilePdf} /> {extrairNomeAto(dados.ato.nome)}
                                                </Link>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Último Ato Realizado</td>
                                            <td><TextoVazio>{pegarUltimoAto(dados.relacionados)}</TextoVazio></td>
                                        </tr>
                                        <tr>
                                            <td>Data do Último Ato</td>
                                            <td><TextoVazio>{format(parseISO(dados.ato.created_at), 'dd/MM/yyyy')}</TextoVazio></td>
                                        </tr>
                                        <tr>
                                            <td>Documentos do Ato</td>
                                            <td>
                                                {dados.processo_id && 
                                                    <Link onClick={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        abrirModal(dados.processo_id, 'processo');
                                                    }}>
                                                        Ver documentos do ato
                                                    </Link>
    	                                        }
                                                {!dados.processo_id && <TextoVazio />}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Atos Relacionados</td>
                                            <td>
                                                {Boolean(dados.relacionados?.length) && dados.relacionados.map((item, i) => {
                                                    return item.id == id ? null : (
                                                        <span key={item.id} className="mr-2">
                                                            <Link to={`/ato/${item.id}?relacionados=${relacionados}`}>
                                                                {extrairNomeAto(item.nome)}
                                                            </Link>
                                                            {
                                                                i == dados.relacionados.length - 1 || i+1 == dados.relacionados.length - 1 && dados.relacionados[i+1]?.id == id ?
                                                                null : <span className="ml-2">,</span>
                                                            }
                                                        </span>
                                                    );
                                                })}
                                                {!dados.relacionados?.length && <TextoVazio/>}
                                            </td>
                                        </tr>
                                    </DescriptionTable>
                                </Col>
                            </Row>
                        </div>
                        )}
                    </CardBody>
                </Card>
            </Col>
            <ModaisDeVisualizacao id={objetoId} tipo={tipoObjeto} aberto={modalAberta} onClose={fecharModal} onNavigate={abrirModal} />
        </div>
    )
}