import { 
    CARREGAR_SELO,
    CARREGAR_SELO_SUCCESS,
    CARREGAR_SELO_FAILED
} from "./actions";

const INIT_STATE = {
    selo: null,
    loading: false
};

const selos = (state = INIT_STATE, { payload, type }) => {
    switch (type) {
        case CARREGAR_SELO:
            return { ...state, loading: true };
        case CARREGAR_SELO_SUCCESS:
            return { ...state, selo: payload.dados, loading: false };
        case CARREGAR_SELO_FAILED:
            return { ...state, selo: null, loading: false };
        
        default:
            return state;
    }
}

export default selos;