// @flow
import { all, call, takeEvery, put, takeLatest, delay } from 'redux-saga/effects';

import { LOGOUT_USER, trocarCartorioSuccess, TROCAR_CARTORIO, LOGOUT, logoutSuccess, LOGIN_USER_UPDATE_MENU } from './actions';
import { setSession, getLoggedInUser, goToAccounts } from '../../helpers/auth';
import history from '../../helpers/history';
import apiCorreicoes from '../../helpers/apiCorreicoes';
import { toastError, toastWarning } from '../toast/actions';
import { initMenuSuccess } from '../appMenu/actions';
import { loginUserUpdateMenu as loginUserUpdateMenuAction } from './actions';
import { menuRoutes } from '../../routes';
import assignIds from '../appMenu/utils';

function* trocarCartorio({ payload }) {
    let error = 'Não foi possível trocar de cartório';
    try {
        const response = yield call(apiCorreicoes, 'get', `/autenticacao/selecionar-cartorio/${payload.value}`);
        const { status, data } = response;

        switch (status) {
            case 200:
                yield put(trocarCartorioSuccess(data));
                yield put(loginUserUpdateMenuAction(data.livros));
                const user = getLoggedInUser();
                user.token = data.token;
                setSession(user);
                history.push('/');
                break;
            default:
                error = data.erro;
                yield put(toastError(error));
                throw error;
        }
    } catch (e) {
        console.warn('trocarCartorio', e);
    }
}

function* logout({ payload }) {
    // setSession(null); // será feito no accounts
    goToAccounts('logout' + payload.query);
    yield put(logoutSuccess());
}

function* handle401() {
    yield put(toastWarning('Sua sessão expirou'));
    yield delay(2000);
    const routes = assignIds(menuRoutes);
    yield put(initMenuSuccess(routes));
    yield call(logout);
}

function* loginUserUpdateMenu({ payload: { livros } }) {
    let error = 'Não foi possível carregar os livros';
    try {
        if (livros?.length) {
            livros.forEach((especialidade) => {
                especialidade.path = `/especialidades/${especialidade.id}`;
                if (especialidade.livros) {
                    especialidade.livros.forEach((livro) => {
                        if (livro.prefixo_livro && livro.prefixo_livro.endsWith('_PROTOCOLO')) {
                            livro.path = `/especialidades/${especialidade.id}/protocolos/${livro.prefixo_livro}/1`;
                        } else if (especialidade.titulo === 'Tabelionato de Notas') {
                            livro.path = `${especialidade.path}/colecao/${livro.id}/1`;
                        } else {
                            livro.path = `${especialidade.path}/livros/${livro.id}/nome/${livro.prefixo_livro}/1`;
                        }
                    });
                }
            });

            yield put(initMenuSuccess([...livros]));
        }
    } catch (e) {
        yield put(toastError(error));
    }
}

function* authSaga() {
    yield all([
        takeEvery(LOGOUT, logout), 
        takeEvery(LOGOUT_USER, logout), 
        takeEvery(TROCAR_CARTORIO, trocarCartorio),
        takeLatest(LOGIN_USER_UPDATE_MENU, loginUserUpdateMenu),
        takeLatest('ERROR_401', handle401),
    ]);
}

export default authSaga;
