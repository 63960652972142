import { 
    PEGAR_ATO,
    PEGAR_ATO_FAILED,
    PEGAR_ATO_SUCCESS,
} from "./actions";

const INIT_STATE = {
    ato: null,
    loading: false,
};

const atos = (state = INIT_STATE, { payload, type }) => {
    switch (type) {
        case PEGAR_ATO:
            return { ...state, loading: true };
        case PEGAR_ATO_FAILED:
            return { ...state, ato: null, loading: false };
        case PEGAR_ATO_SUCCESS:
            return { ...state, ato: payload.dados, loading: false };

        default:
            return state;
    }
}

export default atos;