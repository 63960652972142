import { CNS_CORREGEDORIA } from "../constants/business";

export default {
    NIVEL5: 'Nível 5',
    NIVEL4: 'Nível 4',
    NIVEL3: 'Nível 3',
    NIVEL2: 'Nível 2',
    NIVEL1: 'Nível 1',
};

export const niveis = {
    ADMIN: {
        nome: 'ADMIN',
        valor: 60,
    },
    NIVEL5: {
        nome: 'NIVEL5',
        valor: 50,
    },
    NIVEL4: {
        nome: 'NIVEL4',
        valor: 40,
    },
    NIVEL3: {
        nome: 'NIVEL3',
        valor: 30,
    },
    NIVEL2: {
        nome: 'NIVEL2',
        valor: 20,
    },
    NIVEL1: {
        nome: 'NIVEL1',
        valor: 10,
    },
};

export const usuarioTemAcesso = (nivelRequerido, usuario, cartorioAtual) => {
    if (!usuario || !cartorioAtual) {
        return false;
    }

    const cartorios = Object.values(usuario.cartorios);
    return usuario.admin === 'S' || cartorioAtual.cns == CNS_CORREGEDORIA || cartorios.find(cartorio => cartorio.cns === CNS_CORREGEDORIA);
};

/**
 * Define se o usuário tem um certo tipo de permissao em um modulo
 * @param {*} nomeModulo acervo, controle_processos, chat, etc
 * @param {*} tipoPermissao visualizar, inserir, editar, excluir
 * @param {*} permissoes lista de todas as permissoes carregadas ao logar
 */
export const temPermissao = (nomeModulo, tipoPermissao, permissoes) => {
    if (!permissoes) {
        return false;
    }

    const permissao = permissoes.find(p => {
        return p.modulo.nome === nomeModulo;
    });

    return permissao ? permissao[tipoPermissao] : false;
};