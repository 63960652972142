import { useEffect, useState } from 'react';
import { io } from 'socket.io-client';

export const useSocket = (url, prefixo) => {
    if (!url) {
        url = process.env.REACT_APP_SOCKETIO;
    }

    if (!prefixo) {
        prefixo = 'virtualcart_database_private-app.';
    }

    const [dados, setDados] = useState(null);
    const [canal, setCanal] = useState(null);

    useEffect(() => {
        if (!canal) return;
        const socket = io(url);
        socket.on(prefixo + canal, mensagem => {
            const obj = JSON.parse(mensagem);
            if (obj) {
                setDados(obj);
            }
        });
    }, [canal])

    return [setCanal, dados];
};
