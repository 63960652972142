export const PEGAR_URL = '@atos/PEGAR_URL';
export const PEGAR_URL_SUCCESS = '@atos/PEGAR_URL_SUCCESS';
export const PEGAR_URL_FAILED = '@atos/PEGAR_URL_FAILED';

export const pegarUrl = (id, tipo) => ({
    type: PEGAR_URL,
    payload: { id, tipo }
});

export const pegarUrlSuccess = (dados) => ({
    type: PEGAR_URL_SUCCESS,
    payload: { dados }
});

export const pegarUrlFailed = () => ({
    type: PEGAR_URL_FAILED
});