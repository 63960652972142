import React, { useEffect } from 'react'
import { useSocket } from './hooks/UseSocket'
import history from './helpers/history'
import { useSelector } from 'react-redux'
import cookies from './helpers/cookies'
import IdleTimer from 'react-idle-timer'
import { formatarHoraMinutoSegundo } from './helpers/numero'
import { getLoggedInUser } from './helpers/auth'
import { registerInterceptor } from './hooks/UseAdapter'
import { toast } from 'react-toastify'

const Bootstrap = ({ tituloPagina }) => {
    /** Error Interceptor */
    useEffect(() => {
        registerInterceptor('ERROR', ({data}) => {
            if (data.erro) {
                toast.error(data.erro)
            }
        })
    }, [])

    const IDLE_TIMEOUT = 30 * 60 * 1000
    const TAB_TIMEOUT = 2 * 60 * 1000

    const logout = () => {
        if (!getLoggedInUser()) {
            return // não faz nada se não estiver logado
        }
            
        history.push('/logout')
    }

    const setTimers = (timers) => {
        cookies().set('timers', timers)
    }

    const getTimers = () => {
        let timers = cookies().get('timers')
        if(!timers) {
            timers = {
                idle: null,
                tab: Date.now()
            }
        }

        return timers
    }

    /* Logout por socket */
    const usuario = useSelector((state) => state.auth.user)
    const [setCanal, dadosSocket] = useSocket()
    
    useEffect(() => {
        if (!usuario) return
        setCanal(usuario.cpf)
    }, [usuario, history])

    useEffect(() => {
        if (dadosSocket && dadosSocket.event == 'App\\Events\\Logout') {
            console.debug('Logout por socket.')
            logout()
        }
    }, [dadosSocket])

    /* Logout por aba fechada */
    const checarTimestampAbaAberta = () => {
        if (!usuario) {
            return
        }

        let timers = getTimers()
        if (Date.now() - timers['tab'] > TAB_TIMEOUT) {
            console.debug('Logout por aba fechada.')
            logout()
        } else {
            timers['tab'] = Date.now()
            setTimers(timers)
        }
    }
    setInterval(checarTimestampAbaAberta, Math.floor(TAB_TIMEOUT / 4))
    useEffect(checarTimestampAbaAberta, [usuario])

    /* Logout por inatividade */
    let idleTimer = null
    setInterval(() => {
        if (!idleTimer) {
            return
        }
        
        let timers = getTimers()
        if (timers['idle'] && Date.now() - 1000 < timers['idle']) {
            // houve uma acao em alguma aba no ultimo segundo?
            idleTimer.reset()
            return
        }
            
        if (idleTimer.getRemainingTime() < IDLE_TIMEOUT / 3) {
            document.title = formatarHoraMinutoSegundo(idleTimer.getRemainingTime()) + ' para encerrar esta sessão.'
        } else {
            document.title = tituloPagina
        }
    }, 1000)

    return <>
        {usuario && 
            <IdleTimer
                ref={ref => { idleTimer = ref }}
                timeout={IDLE_TIMEOUT}
                onAction={() => {
                    // avisar outras abas da ação nessa aba via cookies.
                    let timers = getTimers()
                    timers['idle'] = Date.now()
                    setTimers(timers)
                }}
                debounce={Math.floor(IDLE_TIMEOUT / 30)}
                onIdle={() => {
                    console.debug('Logout por inatividade.')
                    logout()
                }} />
        }
    </>
}

export default Bootstrap