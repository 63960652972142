import React from 'react';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Route } from 'react-router-dom';
import { useAuthenticated, autenticar } from '../hooks/UseAuthenticated';
import { usuarioTemAcesso, niveis } from '../helpers/nivelAcesso';
import { Inicio } from '../pages/Home/Inicio';
import { Livros } from '../pages/Home/Livros';
import { AtosLivro } from '../pages/Home/AtosLivro';
import { CorreicaoAtual } from '../pages/Home/CorreicaoAtual';
import { CorreicoesRealizadas } from '../pages/Home/CorreicoesRealizadas';
import { toastError } from '../store/toast/actions';
import { isUserAuthenticated } from '../helpers/auth';
import { Protocolos } from '../pages/Home/Protocolos';
import { CapaAto } from '../pages/Home/CapaAto';

const Logout = React.lazy(() => import('../pages/Logout/Logout'));

const Error403 = React.lazy(() => import('../pages/other/Error403'));
const Error404 = React.lazy(() => import('../pages/other/Error404'));
const Error500 = React.lazy(() => import('../pages/other/Error500'));

// processa com autorizacao
const PrivateRoute = ({ component: Component, nivel: nivelRequerido, ...props }) => {
    const { user, cartorioAtual, loading } = useAuthenticated(props.location.pathname);
    const dispatch = useDispatch();

    return (
        <Route
            {...props}
            render={(props) => {
                if (loading) {
                    return <></>;
                }

                if (!props.location.search.includes('?code=')) {
                    if (!isUserAuthenticated()) {
                        autenticar();
                        return null;
                    }

                    if (!usuarioTemAcesso(nivelRequerido, user, cartorioAtual)) {
                        dispatch(toastError('Você não tem permissão para acessar essa área.'));
                        return null;
                    }
                }

                // autorizado: vai para a rota
                return <Component {...props} />;
            }}
        />
    );
};

const rootRoute = {
    path: '/',
    exact: true,
    component: () => <Redirect to="/inicio" />,
    route: PrivateRoute,
};

const inicio = {
    path: '/inicio',
    name: 'Início',
    component: Inicio,
    route: PrivateRoute,
    exact: true,
    nivel: niveis.NIVEL1,
    icon: "PieChart",
};

const correicoes = {
    path: '/prepostos',
    name: 'Correições Realizadas',
    route: PrivateRoute,
    exact: true,
    nivel: niveis.NIVEL1,
    component: CorreicoesRealizadas,
    icon: "Clock"
};

const correicaoAtual = {
    path: '/correicao-atual',
    name: 'Dados da Correição Atual',
    route: PrivateRoute,
    exact: true,
    nivel: niveis.NIVEL1,
    component: CorreicaoAtual,
    icon: "List"
};

const listarLivros = {
    path: '/especialidades/:especialidade/:page?',
    exact: true,
    component: () => <Livros />,
    route: PrivateRoute,
};
    
const listarColecoesNotas = {
    path: '/especialidades/:especialidade/colecao/:colecao/:page?',
    exact: true,
    component: () => <Livros />,
    route: PrivateRoute,
};
    
const listarAtosNotas = {
    path: '/especialidades/:especialidade/colecao/:colecao/livros/:livro/nome/:nome/:page?',
    exact: true,
    component: () => <AtosLivro />,
    route: PrivateRoute,
};
    
const listarAtos = {
    path: '/especialidades/:especialidade/livros/:livro/nome/:nome/:page?',
    exact: true,
    component: () => <AtosLivro />,
    route: PrivateRoute,
};

const listarProtocolos = {
    path: '/especialidades/:especialidade/protocolos/:livro/:page',
    exact: true,
    component: () => <Protocolos />,
    route: PrivateRoute,
};

const capaAto = {
    path: '/especialidades/:especialidade/livros/:livro/nome/:nome/ato/:id',
    exact: true,
    component: () => <CapaAto />,
    route: PrivateRoute,
};

const capaAtoNotas = {
    path: '/especialidades/:especialidade/colecao/:colecao/livros/:livro/nome/:nome/ato/:id',
    exact: true,
    component: () => <CapaAto />,
    route: PrivateRoute,
};

const logoutRoute = {
    path: '/logout',
    exact: true,
    name: 'Logout',
    route: Route,
    component: Logout,
};

const autenticacaoRoute = {
    path: '/autenticar',
    exact: true,
    component: () => <></>,
    route: PrivateRoute,
};

const notFoundRoutes = {
    path: '*',
    name: 'Página Não Encontrada',
    component: () => <Redirect to="/error/404" />,
    route: Route,
};

const errorPages = {
    path: '/error',
    name: 'Erros',
    children: [
        {
            path: '/error/404',
            name: 'Página não encontrada',
            component: Error404,
            route: Route,
        },
        {
            path: '/error/403',
            name: 'Sem Permissão',
            component: Error403,
            route: Route,
        },
        {
            path: '/error/500',
            name: 'Erro Interno',
            component: Error500,
            route: Route,
        },
    ],
};

const menuRoutes = [
    inicio,
    // correicoes,
    // correicaoAtual,
];

const appRoutes = [
    inicio,
    correicoes,
    correicaoAtual,
    listarColecoesNotas,
    listarAtosNotas,
    listarAtos,
    listarLivros,
    listarProtocolos,
    capaAto,
    capaAtoNotas,
    autenticacaoRoute,
    errorPages,
    logoutRoute,
];

// flatten the list of all nested routes
const flattenRoutes = (routes) => {
    let flatRoutes = [];

    routes = routes || [];
    routes.forEach((item) => {
        flatRoutes.push(item);

        if (typeof item.children !== 'undefined') {
            flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
        }
    });
    return flatRoutes;
};

// All routes
const allRoutes = [
    rootRoute,
    ...appRoutes,
    notFoundRoutes, // Essa rota tem que sempre ser a ultima
];

const authProtectedRoutes = [...appRoutes];
const allFlattenRoutes = flattenRoutes(allRoutes);
export { allRoutes, authProtectedRoutes, allFlattenRoutes, menuRoutes };
