import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';

/**
 * Renders the PageTitle
 */
const PageTitle = (props) => {
    const items = props.breadCrumbItems || '';
    return (
        <React.Fragment>
            <Breadcrumb className="mt-1 font-size-14">
                <BreadcrumbItem>
                    <Link to="/">{'Início'}</Link>
                </BreadcrumbItem>
                {items.map((item, index) => {
                    return item.active ? (
                        <BreadcrumbItem active key={index}>
                            {item.label}
                        </BreadcrumbItem>
                    ) : (
                        <BreadcrumbItem key={index}>
                            <Link to={item.path ?? '/error/404'}>{item.label}</Link>
                        </BreadcrumbItem>
                    );
                })}
            </Breadcrumb>
        </React.Fragment>
    );
};

export default PageTitle;
