import React, { Component, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Col, Row } from 'reactstrap';
import { DescriptionTable } from './DescriptionTable';

/**
 * Renders the preloader
 */
const PreLoaderWidget = ({ mensagem }) => {
    return (
        <div className="preloader">
            <div className="status">
                <div className="spinner-border avatar-sm text-primary m-2" role="status"></div>
                <br />
                {mensagem && <span>{mensagem}</span>}
            </div>
        </div>
    )
}

export const DelayedLoader = () => {
    const [show, setShow] = useState(false)

    setTimeout(() => {
        setShow(true)
    }, 1000);

    return show && <PreLoaderWidget></PreLoaderWidget>
}

export const CardTitleLoader = ({ width=200 }) => (
    <Row>
        <Col>
            <Row className="mb-2">
                <Col>
                    <h4 className="mt-2"><Skeleton width={width} /></h4>
                </Col>
            </Row>
        </Col>
    </Row>
);

export const DescriptionTableLoader = ({ linhas=10, headerWidth=200 }) => {
    const skeleton = () => <Skeleton />
    return <DescriptionTable headerWidth={headerWidth} data={[...Array(parseInt(linhas))]} valor={skeleton} chave={skeleton} />
};

export const TableLoader = ({ colunas=3, linhas=20 }) => {
    const rLinhas = [...Array(parseInt(linhas))]
    const rColunas = [...Array(parseInt(colunas))]
    return <div className="react-bootstrap-table table-responsive">
        <table className="table table-striped table-bordered">
            <thead>
                <tr>
                    {rColunas.map((o, i) => <th key={i}><Skeleton /></th>)}
                </tr>
            </thead>
            <tbody>
            {rLinhas.map((o, i) => (
                <tr key={i}>
                    {rColunas.map((o, i) => <td key={i}><Skeleton /></td>)}
                </tr>
            ))}
            </tbody>
        </table>
    </div>
}

export default PreLoaderWidget;