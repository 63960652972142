import { useState, useEffect } from 'react'

const EMPTY = '__EMTPY__'

let interceptors = {}

/**
 * Register an interceptor for a given request code.
 * 
 * The interceptor receives the following parameters:
 *     data - The data returned by the api, if any
 *     args - The arguments passed to the api
 * 
 * @param {string|int} code - The code of the error or (START, SUCCESS, ERROR, FINISH).
 * @param {function} fn - The function to be executed.
 */
export const registerInterceptor = (code, fn) => {
  if (!interceptors[code]) {
    interceptors[code] = []
  }

  interceptors[code].push(fn)
}

const runInterceptors = (code, data, args) => {
  if (interceptors[code]) {
    interceptors[code].forEach(fn => fn({data, args}))
  }
}

/**
 * Use an adapter to make a request to a api.
 * 
 * @param {*} adapter The adapter to be used
 * @returns [
 *    {
 *      call, - the function to be used to call the adapter passing a list of arguments
 *      isLoading - the current loading state of the adapter
 *    }, 
 *    response, - whatch this for the response of the last call (useEffect(() => {}, [response]))
 *    error, - whatch this for errors of the last call (useEffect(() => {}, [errorData]))
 *    status - the status of the last call (START, SUCCESS, ERROR, FINISH)
 * ]
 */
export const useAdapter = (adapter) => {
  const [response, setResponse] = useState(null)
  const [error, setError] = useState(null)
  const [status, setStatus] = useState('')
  const [isLoading, setLoading] = useState('')
  const [args, call] = useState(EMPTY)

  useEffect(() => {
    if (args == EMPTY) {
      return
    }

    setStatus('START')
    setLoading(true)
    runInterceptors('START', null, args)
    
    adapter(...args).then((response) => {
      const { status, data } = response;
      if (status === 200) {
        setResponse(data)
        setStatus('SUCCESS')
        runInterceptors('SUCCESS', data, args)
      } else {
        setStatus('ERROR')
        setError(data)
        runInterceptors('ERROR', data, args)
      }

      runInterceptors(status, data, args)
      setStatus('FINISH')
      setLoading(false)
      runInterceptors('FINISH', data, args)
    }, (error) => {
      setStatus('ERROR')
      setError(error)
      runInterceptors('ERROR', error, args)
      setLoading(false)
      runInterceptors('FINISH', error, args)
    })
  }, [args])

  return [{call, isLoading}, response, error, status]
}
