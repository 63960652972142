import { all, call, put, takeEvery } from "redux-saga/effects";
import apiCorreicoes from "../../helpers/apiCorreicoes";
import { toastError } from "../toast/actions";
import { pegarUrlFailed, pegarUrlSuccess, PEGAR_URL } from "./actions";

function* pegarUrl({ payload: { id, tipo } }) {
    let error = 'Não foi possível encontrar o caminho do arquivo.';
    try {
        const response = yield call(apiCorreicoes, 'get', `/anexo/${id}/${tipo}`);
        const { status, data } = response;

        
        switch (status) {
            case 200:
                yield put(pegarUrlSuccess(data));
                break;
            default:
                error = data.erro || error;
                throw error;
        }
    } catch {
        yield put(toastError(error));
        yield put(pegarUrlFailed());
    }
}

function* urlsSaga() {
    yield all([
        takeEvery(PEGAR_URL, pegarUrl), 
    ]);
}

export default urlsSaga;