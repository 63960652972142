export const CARREGAR_SELO = '@selos/CARREGAR_SELO';
export const CARREGAR_SELO_SUCCESS = '@selos/CARREGAR_SELO_SUCCESS';
export const CARREGAR_SELO_FAILED = '@selos/CARREGAR_SELO_FAILED';

export const carregarSelo = (codigoSelo) => ({
    type: CARREGAR_SELO,
    payload: { codigoSelo }
});

export const carregarSeloSuccess = (dados) => ({
    type: CARREGAR_SELO_SUCCESS,
    payload: { dados }
});

export const carregarSeloFailed = () => ({
    type: CARREGAR_SELO_FAILED,
});
