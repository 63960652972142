import React, { useCallback } from "react";
import { ChevronLeft, ChevronRight } from "react-feather";
import { Button } from "reactstrap";

const PAGINA_ATUAL_WIDTH = { width: 90 };

export const Paginator = ({ temProximaPagina, temPaginaAnterior, paginaAtual, proximaPagina, paginaAnterior }) => {
    const onClickPaginaAnterior = useCallback(() => {
        if (paginaAnterior) {
            paginaAnterior();
        }
    }, [paginaAnterior]);

    const onClickProximaPagina = useCallback(() => {
        if (proximaPagina) {
            proximaPagina();
        }
    }, [proximaPagina]);

    return (
        <div className="d-flex align-items-center">
            <Button color={"soft-primary"} onClick={onClickPaginaAnterior} disabled={!temPaginaAnterior}>
                <ChevronLeft />
            </Button>
            <div className="d-flex justify-content-center align-items-center align-self-stretch text-center border rounded mx-2" style={PAGINA_ATUAL_WIDTH}>
                <span>{paginaAtual}</span>
            </div>
            <Button color={"soft-primary"} onClick={onClickProximaPagina} disabled={!temProximaPagina}>
                <ChevronRight />
            </Button>
        </div>
    );
};
