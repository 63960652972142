import { Cookies } from 'react-cookie';

const store = new Cookies();

const opcoesPadrao = {
    path: '/',
    domain: process.env.REACT_APP_COOKIES_DOMAIN,
}

const cookies = () => {
    const set = (chave, valor, opcoes) => {
        const str = JSON.stringify(valor)
        store.set(chave, str, {...opcoes, ...opcoesPadrao})
    }

    const remove = (chave, opcoes) => {
        store.remove(chave, {...opcoes, ...opcoesPadrao})
    }

    const get = (chave) => {
        const valor = store.get(chave)
        return valor ? (typeof valor == 'object' ? valor : JSON.parse(valor)) : null
    }

    return { set, remove, get }
}

export default cookies;