import { ABERTA } from "./actions";

const INIT_STATE = {
    agora: Date.now()
};

const abaAberta = (state = INIT_STATE, { type }) => {
    switch (type) {
        case ABERTA:
            return { ...state, agora: Date.now() };
        
            default:
            return state;
    }
}

export default abaAberta;