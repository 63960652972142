import { 
    LISTAR_ATOS_DE_LIVRO,
    LISTAR_ATOS_DE_LIVRO_FAILED,
    LISTAR_ATOS_DE_LIVRO_SUCCESS,
    LISTAR_SUBLIVROS,
    LISTAR_SUBLIVROS_SUCCESS,
    LISTAR_SUBLIVROS_FAILED
} from "./actions";

const INIT_STATE = {
    data: {},
    loading: false
};

const livros = (state = INIT_STATE, { payload, type }) => {
    switch (type) {
        case LISTAR_SUBLIVROS:
            return { ...state, loading: true };
        case LISTAR_SUBLIVROS_SUCCESS:
            return { ...state, livros: payload.dados, loading: false };
        case LISTAR_SUBLIVROS_FAILED:
            return { ...state, loading: false };
        
        case LISTAR_ATOS_DE_LIVRO:
            return { ...state, loading: true };
        case LISTAR_ATOS_DE_LIVRO_SUCCESS:
            return { ...state, data: payload.dados, loading: false };
        case LISTAR_ATOS_DE_LIVRO_FAILED:
            return { ...state, loading: false };

        default:
            return state;
    }
}

export default livros;