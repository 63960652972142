import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Container, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { Paperclip, Award } from 'react-feather';
import { CardTitleLoader, TableLoader } from "./Loader";
import { carregarProcesso } from '../store/processos/actions';
import { format, parseISO } from "date-fns";
import { FileIcon } from './FileIcon'
import { pegarUrl } from "../store/actions";
import { DOCS_URL } from "../constants/docsUrl";
import { Link } from "./Link";
import { useAdapter } from "../hooks/UseAdapter";
import apiCorreicoes from "../helpers/apiCorreicoes";

export const ModalProcesso = ({id, aberto, onClose, onNavigate}) => {
    // const { id } = useParams();
    const [anexoAtual, setAnexoAtual] = useState();
    const dispatch = useDispatch();
    const { loadingAnexos, url } = useSelector(({ anexos }) => anexos);
    const { loading, processo } = useSelector(({ processos }) => {
        return {loading: processos.loading, processo: processos.processo };
    });

    useEffect(() => {
        dispatch(carregarProcesso(id));
    }, []);

    const [{ call }, anexoUrl] = useAdapter(apiCorreicoes);
    const abrirArquivo =  (anexo) => {
        setAnexoAtual(anexo);
        call(['get', `/anexo/${anexo.id}/${anexo.origem}`]);
    };
    useEffect(() => {
        if (!anexoAtual) {
            return;
        }

        let url = anexoUrl.url;
        if (["doc", "docx"].includes(anexoAtual.extensao)) {
            url = `${DOCS_URL}${encodeURIComponent(url)}`;
        }
        const newWindow = window.open(url, "_blank", "noopener,noreferrer");
        if (newWindow) {
            newWindow.opener = null
        }
    }, [anexoUrl]);

    return (
        <Modal
            isOpen={aberto}
            toggle={onClose}
            className="modal-dialog-centered"
            style={{ zIndex: 9999999999 }}
            size="lg">
            <ModalHeader>
                {loading && <CardTitleLoader />}
                {!loading && `Processo ${processo?.numero}`}
            </ModalHeader>
            <ModalBody>
                { (loading || processo?.anexos?.length) ?
                    <Row>
                        <Col>
                            <h5 className="mt-2"><Paperclip size={18}/> Documentos do Processo</h5>
                        </Col>
                    </Row> : ''
                }
                <Row className="mb-4">
                    <Col>
                        {loading && <TableLoader colunas={1} linhas={3} />}
                        {!loading && processo?.anexos?.slice(0, 99).map((anexo, index) => 
                            <Link onClick={() => {abrirArquivo(anexo);}} key={anexo.id}>
                                <Row
                                    className={`ml-4 mr-1 atos-card font-weight-light atos 
                                        atos-${(index + 1) % 2 === 0 ? 'par' : 'impar'} 
                                        ${index === 0 ? 'border-top-radius' : ''}
                                        ${index === processo?.anexos.length - 1 ? 'border-bottom-radius' : ''}`}>
                                    <Container fluid={true}>
                                        <Row className="ml-2 d-flex align-items-center">
                                            <Col xs={9}>
                                                <FileIcon extensao={anexo.extensao?.toLowerCase()} />
                                                <h5 className={`float-left atos-${(index + 1) % 2 === 0 ? 'par' : 'impar'}`}>
                                                    {anexo.titulo}
                                                </h5>
                                            </Col>
                                            <Col xs={3}>
                                                <small className="float-right">{format(parseISO(anexo.created_at), 'HH:ii dd/MM/yyyy')}</small>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Row>
                            </Link>
                        )}
                        {!loading && processo?.anexos?.length >= 100 && (
                            <Row
                                className={`ml-4 mr-1 atos-card font-weight-light atos atos-par border-bottom-radius`}>
                                <Col>
                                    Não foi possível carregar todos os documentos. Entre em contato com o suporte para resolver o problema.
                                </Col>
                            </Row>
                        )}
                    </Col>
                </Row>


                {!loading && processo?.documentos?.slice(0, 99).map((documento, index) => 
                    <React.Fragment key={index}>
                        <Row>
                            <Col>
                                <h5 className="mt-2"><Paperclip size={18}/> Documentos Pessoais de { documento.cpf_cnpj }</h5>
                            </Col>
                        </Row>
                        <Row className="mb-4">
                            <Col>
                                {documento?.documentos?.slice(0, 99).map((doc, index) => 
                                    <Link onClick={() => {abrirArquivo(doc);}} key={doc.id}>
                                        <Row
                                            className={`ml-4 mr-1 atos-card font-weight-light atos 
                                                atos-${(index + 1) % 2 === 0 ? 'par' : 'impar'} 
                                                ${index === 0 ? 'border-top-radius' : ''}
                                                ${index === documento?.documentos.length - 1 ? 'border-bottom-radius' : ''}`}>
                                            <Container fluid={true}>
                                                <Row className="ml-2 d-flex align-items-center">
                                                    <Col xs={9}>
                                                        <FileIcon extensao={doc.extensao?.toLowerCase()} />
                                                        <h5 className={`float-left atos-${(index + 1) % 2 === 0 ? 'par' : 'impar'}`}>
                                                            {doc.titulo}
                                                        </h5>
                                                    </Col>
                                                    <Col xs={3}>
                                                        <small className="float-right">{format(parseISO(doc.created_at), 'HH:ii dd/MM/yyyy')}</small>
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </Row>
                                    </Link>
                                )}
                            </Col>
                        </Row>
                    </React.Fragment>
                )}


                { (loading || processo?.selos?.length) ?
                    <Row>
                        <Col>
                            <h5 className="mt-2"><Award size={18}/> Selos</h5>
                        </Col>
                    </Row> : ''
                }
                <Row className="mb-4">
                    <Col>
                        {loading && <TableLoader colunas={1} linhas={3} />}
                        {!loading && processo?.selos?.slice(0, 99).map((selo, index) => 
                            <Row
                                key={selo.id}
                                className={`ml-4 mr-1 atos-card font-weight-light atos 
                                    atos-${(index + 1) % 2 === 0 ? 'par' : 'impar'} 
                                    ${index === 0 ? 'border-top-radius' : ''}
                                    ${index === processo?.selos.length - 1 ? 'border-bottom-radius' : ''}`}>
                                <Container>
                                    <Row className="ml-2 d-flex align-items-center">
                                        <Col xs={9}>
                                            <Link onClick={() => {onNavigate(selo.numero, 'selo');}}>
                                                <h5 className={`float-left link atos-${(index + 1) % 2 === 0 ? 'par' : 'impar'}`}>
                                                    <u>{selo.numero}</u>
                                                </h5>
                                            </Link>
                                        </Col>
                                        <Col xs={3}>
                                            <small className="float-right">{format(parseISO(selo.created_at), 'HH:ii dd/MM/yyyy')}</small>
                                        </Col>
                                    </Row>
                                </Container>
                            </Row>
                        )}
                        {!loading && processo?.selos?.length >= 100 && (
                            <Row
                                className={`ml-4 mr-1 atos-card font-weight-light atos atos-par border-bottom-radius`}>
                                <Col>
                                    Não foi possível carregar todos os selos. Entre em contato com o suporte para resolver o problema.
                                </Col>
                            </Row>
                        )}
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button
                    color="primary"
                    className="ml-1"
                    onClick={onClose}>
                    Ok
                </Button>
            </ModalFooter>
        </Modal>
    )
}