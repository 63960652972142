/**
 * @param texto original (cpf ou cnpj)
 * @returns texto limpo sem formatacao de cpf ou cnpj (sem pontos, hifens ou barras)
 */
export const limparTextoDeBusca = (texto) => {
    return texto.split('.').join('').split('-').join('').split('/').join('');
};

export function validURL(str) {
    var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(str);
  }

export function toTitleCase(str) {
  const ignorados = [
      [' De ', ' de '],
      [' A ', ' a '],
      [' O ', ' o '],
      [' Que ', ' que '],
      [' E ', ' e '],
      [' Do ', ' do '],
      [' Da ', ' da '],
      [' Em ', ' em '],
      [' Um ', ' um '],
      [' Com ', ' com '],
      [' Uma ', ' uma '],
      [' Os ', ' os '],
      [' No ', ' no '],
      [' Na ', ' na '],
      [' As ', ' as '],
      [' Dos ', ' dos '],
      [' Ao ', ' ao '],
      [' Das ', ' das '],
      [' À ', ' à '],
      [' Sem ', ' sem '],
      [' Aos ', ' aos '],
      [' Nas ', ' nas '],
      [' Num ', ' num '],
      [' Nem ', ' nem '],
      [' Numa ', ' numa '],
  ];

  let resultado = str.replace(
    /\w\S*/g,
    function(txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
  );

  ignorados.map(([ busca, substituto ]) => {
    resultado = resultado.replaceAll(busca, substituto);
  });

  return resultado;
}
