// @flow
import { all } from 'redux-saga/effects';
import authSaga from './auth/saga';
import toastSaga from './toast/saga';
import appMenu from './appMenu/saga';
import livrosSaga from './livros/saga';
import atosSaga from './atos/saga';
import protocolosSaga from './protocolos/saga';
import selosSaga from './selos/saga';
import processosSaga from './processos/saga';
import anexosSaga from './anexos/saga';

export default function* rootSaga(getState) {
    yield all([
        authSaga(),
        toastSaga(),
        appMenu(),
        livrosSaga(),
        atosSaga(),
        protocolosSaga(),
        selosSaga(),
        processosSaga(),
        anexosSaga(),
    ]);
}
