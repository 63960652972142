export const PEGAR_ATO = '@atos/PEGAR_ATO';
export const PEGAR_ATO_SUCCESS = '@atos/PEGAR_ATO_SUCCESS';
export const PEGAR_ATO_FAILED = '@atos/PEGAR_ATO_FAILED';

export const pegarAto = (id, relacionados) => ({
    type: PEGAR_ATO,
    payload: { id, relacionados }
});

export const pegarAtoSuccess = (dados) => ({
    type: PEGAR_ATO_SUCCESS,
    payload: { dados }
});

export const pegarAtoFailed = () => ({
    type: PEGAR_ATO_FAILED
});