import { 
    PEGAR_URL,
    PEGAR_URL_FAILED,
    PEGAR_URL_SUCCESS,
} from "./actions";

const INIT_STATE = {
    url: null,
    loading: false,
};

const urls = (state = INIT_STATE, { payload, type }) => {
    switch (type) {
        case PEGAR_URL:
            return { ...state, url: null, loading: true };
        case PEGAR_URL_FAILED:
            return { ...state, url: null, loading: false };
        case PEGAR_URL_SUCCESS:
            return { ...state, url: payload.dados.url, loading: false };

        default:
            return INIT_STATE; // evita uso de estado antigo disparando downloads não solicitados ao iniciar o componente.
    }
}

export default urls;