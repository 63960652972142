import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
    faFilePdf,
    faFile,
    faFileArchive,
    faFileAudio,
    faFileExcel,
    faFileImage,
    faFileVideo,
    faFileWord
} from '@fortawesome/free-regular-svg-icons'

export function FileIcon({ extensao }) {
    switch (extensao) {
        case 'pdf':
            return <FontAwesomeIcon icon={faFilePdf} size="lg" color="red" className="mr-2 mt-2 float-left" />;
        case 'jpg':
        case 'jpeg':
        case 'png':
        case 'gif':
        case 'bmp':
            return <FontAwesomeIcon icon={faFileImage} size="lg" color="green" className="mr-2 mt-2 float-left" />;
        case 'zip':
        case 'rar':
        case 'tar':
            return <FontAwesomeIcon icon={faFileArchive} size="lg" color="olive" className="mr-2 mt-2 float-left" />;
        case 'mp4':
        case 'avi':
            return <FontAwesomeIcon icon={faFileVideo} size="lg" color="purple" className="mr-2 mt-2 float-left" />;
        case 'mp3':
            return <FontAwesomeIcon icon={faFileAudio} size="lg" color="purple" className="mr-2 mt-2 float-left" />;
        case 'xls':
        case 'xlsx':
            return <FontAwesomeIcon icon={faFileExcel} size="lg" color="green" className="mr-2 mt-2 float-left" />;
        case 'doc':
        case 'docx':
            return <FontAwesomeIcon icon={faFileWord} size="lg" color="blue" className="mr-2 mt-2 float-left" />;
        default:
            return <FontAwesomeIcon icon={faFile} size="lg" color="grey" className="mr-2 mt-2 float-left" />;
    }
}
