import React from 'react';
import { ToastContainer } from 'react-toastify';
import Routes from './routes/Routes';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.css';
import Bootstrap from './Bootstrap';

// Theme
import './assets/scss/theme.scss';

const App = () => {
    return (
        <>
            <Bootstrap tituloPagina="Correição On-line - Virtual Cart" />
            <Routes></Routes>
            <ToastContainer />
        </>
    );
}

export default App;
