export const CARREGAR_PROCESSO = '@processos/CARREGAR_PROCESSO';
export const CARREGAR_PROCESSO_SUCCESS = '@processos/CARREGAR_PROCESSO_SUCCESS';
export const CARREGAR_PROCESSO_FAILED = '@processos/CARREGAR_PROCESSO_FAILED';

export const carregarProcesso = (id) => ({
    type: CARREGAR_PROCESSO,
    payload: { id }
});

export const carregarProcessoSuccess = (dados) => ({
    type: CARREGAR_PROCESSO_SUCCESS,
    payload: { dados }
});

export const carregarProcessoFailed = () => ({
    type: CARREGAR_PROCESSO_FAILED,
});
