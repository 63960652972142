import React, { useCallback, useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { AlertCircle } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";
import PageTitle from "../../components/PageTitle";
import { listarAtosDeLivro } from "../../store/livros/actions";
import { CardTitleLoader, TableLoader } from '../../components/Loader';
import { format } from "date-fns";
import { Paginator } from "../../components/Paginator";
import { encontrarLivro, formatarTituloColecaoPorNome, formatarTituloLivroPorNome } from "../../helpers/livros";

export const AtosLivro = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { especialidade: especialidadeId, livro: livroId, colecao: colecaoId, nome, page } = useParams();
    const { especialidade, livro, colecao } = useSelector(({ appMenu: { menuItems } }) => ({
        especialidade: encontrarLivro(especialidadeId, menuItems),
        livro: encontrarLivro(livroId, menuItems),
        colecao: encontrarLivro(colecaoId, menuItems)
    }));

    const [ breadcrumbs, setBreadcrumbs ] = useState([]);
    useEffect(() => {
        if (colecaoId) {
            setBreadcrumbs([
                { label: especialidade.titulo, path: especialidade.path },
                { label: formatarTituloColecaoPorNome(nome), path: colecao.path },
                { label: livro ? livro.titulo : formatarTituloLivroPorNome(nome), active: true }
            ]);
        } else {
            setBreadcrumbs([
                { label: especialidade.titulo, path: especialidade.path },
                { label: livro ? livro.titulo : formatarTituloLivroPorNome(nome), active: true }
            ]);
        }
    }, []);

    const gerarLinkAto = (atos_relacionados) => {
        if (colecaoId) {
            return `/especialidades/${especialidadeId}/colecao/${colecaoId}/livros/${livroId}/nome/${nome}/ato/${atos_relacionados.slice(-1).pop()}?relacionados=${atos_relacionados.join(',')}`
        }

        return `/especialidades/${especialidadeId}/livros/${livroId}/nome/${nome}/ato/${atos_relacionados.slice(-1).pop()}?relacionados=${atos_relacionados.join(',')}`
    }

    const {
        loading,
        data: {
            pagina_atual: paginaAtual,
            tem_pagina_anterior: temPaginaAnterior,
            tem_proxima_pagina: temProximaPagina,
            dados: livros,
        },
    } = useSelector(({ livros }) => livros);

    const columns = React.useMemo(
        () => [
            {
                text: 'Nome',
                dataField: 'numero_ato',
                formatter: (_, row) => {
                    return row.data_ultimo_ato ? 
                    <Link to={gerarLinkAto(row.atos_relacionados)}>
                        <div>
                            {row.numero_ato}
                        </div>
                    </Link> : row.numero_ato;
                }
            },
            {
                text: 'Último ato realizado',
                dataField: 'ultimo_ato',
            },
            {
                text: 'Data do último ato',
                dataField: 'data_ultimo_ato',
                formatter: (_, row) => row.data_ultimo_ato ? format(new Date(row.data_ultimo_ato), "dd/MM/yyyy HH:mm:ss") : null,
            },
        ],
        []
    );

    useEffect(() => {
        dispatch(listarAtosDeLivro(livroId, nome, page));
    }, [nome, page, dispatch]);

    const goToPaginaAnterior = useCallback(() => {
        history.push(`/especialidades/${especialidadeId}/livros/${livroId}/nome/${nome}/${(paginaAtual || page) - 1}`);
    }, [history, especialidadeId, livroId, nome, paginaAtual, page]);
    
    const goToProximaPagina = useCallback(() => {
        history.push(`/especialidades/${especialidadeId}/livros/${livroId}/nome/${nome}/${(paginaAtual || page) + 1}`);
    }, [history, especialidadeId, livroId, nome, paginaAtual, page]);


    return (
        <div className="content-page-menu-left">
            <Col className="container">
                <Row className="page-title">
                    <Col>
                    {loading ? <CardTitleLoader width={400} /> : <PageTitle breadCrumbItems={breadcrumbs} /> }
                    </Col>
                </Row>
                <Card>
                    <CardBody>
                        {!loading && (
                            <Row>
                                <Col>
                                    <Row className="mb-2">
                                        <Col>
                                            <h4 className="mt-2">{livro ? livro.titulo : formatarTituloLivroPorNome(nome)}</h4>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        )}
                        {loading && <div>
                            <CardTitleLoader />
                            <TableLoader colunas="3" linhas="20" />    
                        </div>}
                        {!loading && livros?.length ? <BootstrapTable
                            bootstrap4
                            striped
                            keyField="numero_ato"
                            remote
                            onTableChange={() => null}
                            data={livros}
                            columns={columns}
                            wrapperClasses="table-responsive"
                        /> : null}
                        {!livros?.length && !loading ? <Col className="d-flex flex-column justify-content-center align-items-center">
                            <AlertCircle />
                            <h6 className="font-size-15">Nenhum ato cadastrado</h6>
                        </Col> : null}
                        {Boolean(livros?.length) && <Row className="mt-4">
                            <Col>
                                <Row className="float-right">
                                    <Col>
                                        <Paginator 
                                            paginaAtual={paginaAtual} 
                                            proximaPagina={goToProximaPagina}
                                            paginaAnterior={goToPaginaAnterior}
                                            temProximaPagina={temProximaPagina}
                                            temPaginaAnterior={temPaginaAnterior}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>}
                    </CardBody>
                </Card>
            </Col>
        </div>
    );
};