import React from "react";
import { Link as RouterLink } from "react-router-dom";

export const Link = ({ to, className, children, ...resto }) => {
    if (!className) {
        className = ""
    }

    if (!to) {
        return <span className={`${className} link`} {...resto}>{children}</span>
    }

    return (
        <RouterLink to={to} {...resto}>
        {children}
        </RouterLink>
    );
}