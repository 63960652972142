import { all, call, put, takeEvery } from "redux-saga/effects";
import apiCorreicoes from "../../helpers/apiCorreicoes";
import { toastError } from "../toast/actions";
import { FORCAR_ATUALIZACAO_PROTOCOLOS, listarProtocolosFailed, listarProtocolosSuccess, LISTAR_PROTOCOLOS } from "./actions";

function* forcarAtualizacaoProtocolos({ payload: { livro, pagina } }) {
    let error = 'Não foi possível listar os protocolos.';
    try {
        const response = yield call(apiCorreicoes, 'get', `/protocolos/forcar-atualizacao/${livro}?page=${pagina}`);
        const { status, data } = response;

        switch (status) {
            case 200:
                yield put(listarProtocolosSuccess(data));
                break;
            default:
                error = data.erro || error;
                throw error;
        }
    } catch {
        yield put(toastError(error));
        yield put(listarProtocolosFailed());
    }
}

function* listarProtocolos({ payload: { livro, pagina } }) {
    let error = 'Não foi possível listar os protocolos.';
    try {
        const response = yield call(apiCorreicoes, 'get', `/protocolos/${livro}?page=${pagina}`);
        const { status, data } = response;

        switch (status) {
            case 200:
                yield put(listarProtocolosSuccess(data));
                break;
            default:
                error = data.erro || error;
                throw error;
        }
    } catch {
        yield put(toastError(error));
        yield put(listarProtocolosFailed());
    }
}

function* protocolosSaga() {
    yield all([
        takeEvery(LISTAR_PROTOCOLOS, listarProtocolos), 
        takeEvery(FORCAR_ATUALIZACAO_PROTOCOLOS, forcarAtualizacaoProtocolos), 
    ]);
}

export default protocolosSaga;